var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
export default function Drop(_a) {
    var onLoaded = _a.onLoaded;
    var styles = {
        container: {
            textAlign: "center",
            padding: 20,
            marginTop: 12,
            color: 'red',
            fontSize: 18,
            fontWeight: 600,
            borderRadius: 4,
            userSelect: "none",
            outline: 0,
            cursor: "pointer",
        },
    };
    var onDrop = useCallback(function (acceptedFiles) {
        onLoaded(acceptedFiles);
        // Do something with the files
    }, []);
    var _b = useDropzone({
        onDrop: onDrop,
        accept: "application/pdf",
    }), getRootProps = _b.getRootProps, getInputProps = _b.getInputProps, isDragActive = _b.isDragActive;
    return (_jsxs("div", __assign({}, getRootProps(), { style: styles.container, children: [_jsx("input", __assign({}, getInputProps())), isDragActive ? _jsx("p", { children: "Drop a PDF here" }) : _jsx("p", { children: "Drag a PDF here" })] })));
}
