var rightPanelstyles = {
    dataExportContainer: {
        backgroundColor: "white",
        width: "100%",
        height: "100%",
        margin: "1rem",
        padding: "0.5rem",
        borderRadius: "15px",
        boxShadow: "5px 5px lightgrey",
        '-moz-box-shadow': '0 0 3px lightgrey',
        '-webkit-box-shadow': '0 0 3px lightgrey',
        'box-shadow': '0 0 3px lightgrey',
    },
};
var orgStyles = {
    org: {
        // backgroundColor: "red",
        // paddingY: "200px",
        width: "100%",
        overflowY: "scroll"
    },
    saveText: {
        paddingRight: '10px',
        color: "#1976D2", // Blue color
        cursor: "pointer",
        fontWeight: "bold",
        textTransform: "uppercase",
        display: "flex",
        alignItems: "center",
        transition: "all 0.3s ease",
        marginBottom: "1em", // Moved up a little bit
        '&:hover': {
            color: "#1565C0",
            transform: "scale(1.05)",
        },
        '@keyframes pulse': {
            '0%': { opacity: 1 },
            '50%': { opacity: 0.7 },
            '100%': { opacity: 1 },
        },
        animation: 'pulse 3s infinite ease-in-out',
    }
};
export { rightPanelstyles, orgStyles };
