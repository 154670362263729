var settingsStyles = {
    settingsContainer: {
        width: "100%",
        // backgroundColor: "red",
    },
    componentContainer: {
        backgroundColor: "white",
        width: "100%",
        height: "100%",
        // margin: "1rem",
        marginRight: "1rem",
        padding: "1rem",
        borderRadius: "15px",
        boxShadow: "5px 5px lightgrey",
        '-moz-box-shadow': '0 0 3px lightgrey',
        '-webkit-box-shadow': '0 0 3px lightgrey',
        'box-shadow': '0 0 3px lightgrey',
        // backgroundColor: "red",
    }
};
export { settingsStyles };
