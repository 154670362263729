var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDataProvider, useResourceContext, useNotify, useRefresh, useTranslate, } from 'react-admin';
import { Typography, Grid } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { general } from '../utils/componentWizard';
import SelectedRowLeftColumn from "./SelectedRowLeftColumn";
import EditDialog from './EditDialog';
import { userListstyles as styles } from './helper/styles';
import { dummyData } from './helper/dummyData';
import TableDatagrid from './TableDatagrid';
import FilterList from './FilterList';
import { ACLData } from '../utils/dummyData';
import { GenderMuiChoices } from '../subjectPage/Params';
import { OrganizationMuiChoices, UserGroupMuiChoices } from './helper/Params';
var UserList = function (props) {
    var location = useLocation();
    var navigate = useNavigate();
    var resource = useResourceContext(props);
    var attributeResource = "".concat(useResourceContext(props), "/userList");
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    var translate = useTranslate();
    var _a = useState(dummyData), data = _a[0], setData = _a[1]; //data table data
    // const resource = "UserList";
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(ACLData), acessControlList = _c[0], setAcessControlList = _c[1];
    var _d = useState({
    // analyzeFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // analyzeToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    }), filters = _d[0], setFilters = _d[1];
    var _e = useState({ gender: GenderMuiChoices, organizations: OrganizationMuiChoices, userGroups: UserGroupMuiChoices }), params = _e[0], setParams = _e[1]; //data table data
    var _f = useState(false), openLeftPanel = _f[0], setOpenLeftPanel = _f[1];
    var _g = useState({}), selectedRow = _g[0], setSelectedRow = _g[1];
    var _h = useState({}), selectedViewUser = _h[0], setSelectedViewUser = _h[1]; //when onclick the "eye" btn on list
    var _j = useState({}), origDialogData = _j[0], setOrigDialogData = _j[1]; //original dialogData
    // dialog for preview export
    var _k = useState(false), dialogOpened = _k[0], setDialogOpened = _k[1];
    var _l = useState({}), dialogData = _l[0], setDialogData = _l[1];
    // data table header
    var _m = useState([
        {
            "id": translate("pages.subject.fields.id"),
            "hkid": translate("pages.subject.fields.hkid"),
            "firstname": translate("pages.subject.fields.firstname"),
            "lastname": translate("pages.subject.fields.lastname"),
            "gender": translate("pages.subject.fields.gender"),
            "educationLevel": translate("pages.subject.fields.educationLevel"),
            "dateOfBirth": translate("pages.subject.fields.dateOfBirth"),
            "isHeader": true,
            "selected": false
        }
    ]), dataGridHeader = _m[0], setDataGridHeader = _m[1];
    var delAction = function (record) {
        var params = {
            data: {
                id: record.id,
                // hkid: record.hkid,  //[back] later switch to hkid
                // firstname: record.firstname,
                // lastname: record.lastname,
                // gender: record.gender,
                // educationLevel: record.educationLevel,
                // dateOfBirth: record.dateOfBirth,
                // createby: 1,
                givenId: record.id //[back] later switch to real id - id for db, not hkid
            }
        };
        dataProvider.delete(resource, params)
            .then(function () {
            refresh();
            notify('User deleted');
        });
    };
    var reloadRecord = function (values, dialogType) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedPassword, updatedConfirmpw, params_1, updatedData;
        return __generator(this, function (_a) {
            setIsLoading(true);
            try {
                console.log('==log submit from dialog', values, dialogType);
                updatedPassword = (!values.password || values.password === "") ? null : values.password;
                updatedConfirmpw = (!values.confirmpw || values.confirmpw === "") ? null : values.confirmpw;
                if (updatedPassword != updatedConfirmpw) {
                    notify("Password not the same");
                    return [2 /*return*/];
                }
                params_1 = {
                    data: {
                        // // "id": values.id,
                        // "username": values.username,
                        "Last_name": values.Last_name,
                        "First_name": values.First_name,
                        // "email": values.email,
                        "language": values.language,
                        "gender": values.gender,
                        // "organizations": [values.organizations],
                        // "userGroups": [values.userGroups],
                        // "password": values.password
                    }
                };
                if (values.password) {
                    params_1.data.password = values.password;
                }
                if (values.username) {
                    params_1.data.username = values.username;
                }
                if (values.email) {
                    params_1.data.email = values.email;
                }
                if (values.editOrganizations) {
                    params_1.data.organizationIds = [values.editOrganizations];
                }
                if (values.editUserGroups) {
                    params_1.data.userGroupIds = values.editUserGroups;
                }
                // if (values.userGroups) {
                //     params.data.userGroups = [values.userGroups];
                // }
                console.log(values.organizations, values.userGroups, '==poi');
                //[back] [0821] uncomment below
                console.log(values, '==values98');
                switch (dialogType) {
                    case "Create":
                        updatedData = JSON.parse(JSON.stringify(data));
                        updatedData.push(values);
                        setData(updatedData);
                        console.log('==create params', params_1);
                        dataProvider.create(attributeResource, params_1)
                            .then(function (returnData) {
                            refresh();
                            notify('User added');
                            setIsLoading(false);
                        }).catch(function (error) {
                            // Handle any errors that occurred
                            console.error(error);
                            refresh();
                            notify('User create failed');
                            setIsLoading(false);
                        });
                        break;
                    case "Edit":
                        params_1.data.id = values.id;
                        // params.data.givenId = values.id;
                        dataProvider.updateOne(attributeResource, params_1, values.id)
                            .then(function () {
                            refresh();
                            notify('User updated');
                            setIsLoading(false);
                        }).catch(function (error) {
                            // Handle any errors that occurred
                            console.error(error);
                            refresh();
                            notify('User update failed');
                            setIsLoading(false);
                        });
                        break;
                }
                setDialogData(__assign({}, values));
            }
            catch (err) {
                console.log(err, '==err');
                refresh();
                notify('User update fail');
                setIsLoading(false);
                // dispatch(actionTypes.SNACKBAR_ERROR);
            }
            closeDialog();
            return [2 /*return*/];
        });
    }); };
    var setDialogDataAction = function (record) {
        var _a, _b, _c, _d, _e;
        console.log(record, params, '==dialog data action');
        setDialogData(__assign({ submit: null, dialogType: (record === null || record === void 0 ? void 0 : record.id) ? "Edit" : "Create", id: (_a = record === null || record === void 0 ? void 0 : record.id) !== null && _a !== void 0 ? _a : null, editOrganizations: (_c = (_b = record === null || record === void 0 ? void 0 : record.current_organization) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : null, editUserGroups: (_e = (_d = record === null || record === void 0 ? void 0 : record.userGroups) === null || _d === void 0 ? void 0 : _d.map(function (group) { return group.id; })) !== null && _e !== void 0 ? _e : null }, record
        // hkid: record?.hkid ?? null,
        // lastname: record?.lastname ?? null,
        // firstname: record?.firstname ?? null,
        // gender: record?.gender ?? null,
        // dateOfBirth: record?.dateOfBirth ?? null,
        ));
    };
    var openEditDialog = function (record) {
        console.log('==log record 123', record);
        setDialogDataAction(record);
        // formik?.current?.resetForm();
        setDialogOpened(true);
    };
    var leftPanelOpenEditDialog = function () {
        console.log('==log record 993', dialogData);
        // setDialogDataAction(dialogData)
        setDialogData(__assign({ submit: null, dialogType: "Edit" }, selectedRow));
        // formik?.current?.resetForm();
        setDialogOpened(true);
    };
    var closeDialog = function () {
        setDialogOpened(false);
    };
    var closeLeftPanel = function () {
        setSelectedRow({});
        setOpenLeftPanel(false);
    };
    useEffect(function () { return __awaiter(void 0, void 0, void 0, function () {
        var sessonStorageJSON, cachedAuth, settingsParams, newParams, orgParams, orgData, userGroupData;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
                    if (sessonStorageJSON) {
                        setFilters(__assign(__assign({}, filters), sessonStorageJSON));
                    }
                    sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(__assign(__assign({}, filters), sessonStorageJSON)));
                    searchFilterGroup();
                    cachedAuth = (_b = JSON.parse(localStorage.getItem('auth'))) !== null && _b !== void 0 ? _b : {};
                    settingsParams = {};
                    if (cachedAuth) {
                        settingsParams.id = cachedAuth.id;
                        dataProvider.getOneWithoutDataJSON("users", settingsParams)
                            .then(function (rtnData) {
                            var _a;
                            var rtnACL = __assign({}, (_a = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _a === void 0 ? void 0 : _a.access);
                            var newACList = __assign({}, ACLData);
                            newACList.access = __assign({}, rtnACL);
                            setAcessControlList(newACList);
                        }).catch(function (error) {
                            // Handle any errors that occurred
                            console.error(error);
                        });
                    }
                    newParams = __assign({}, params);
                    orgParams = {
                        pagination: { page: 1, perPage: 25 },
                        sort: { field: "id", order: "ASC" },
                        // filter: updatedFilters //[back][0715] resume this
                        filter: {}
                    };
                    return [4 /*yield*/, dataProvider.getListNoFilter("organizations", orgParams)];
                case 1:
                    orgData = (_f.sent());
                    orgData = (_c = orgData === null || orgData === void 0 ? void 0 : orgData.data) === null || _c === void 0 ? void 0 : _c.map(function (item) { return ({ id: item.id, name: item.orgName, value: item.id }); });
                    return [4 /*yield*/, dataProvider.getListNoFilter("userGroup", orgParams)];
                case 2:
                    userGroupData = (_f.sent());
                    userGroupData = (_e = (_d = userGroupData === null || userGroupData === void 0 ? void 0 : userGroupData.data) === null || _d === void 0 ? void 0 : _d.UserGroup) === null || _e === void 0 ? void 0 : _e.map(function (item) { return ({ id: item.id, name: item.name, value: item.id }); });
                    newParams['organizations'] = orgData;
                    newParams['userGroups'] = userGroupData;
                    setParams(newParams);
                    console.log(orgData, '==orgData', userGroupData);
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, []);
    var getCurrentViewingUser = function (userId) {
        var settingsParams = { id: userId };
        dataProvider.getOneWithoutDataJSON("users", settingsParams)
            .then(function (rtnData) {
            var _a;
            // console.log(userId, { ...ACLData,...rtnData.profile.access }, rtnData.profile.access, '==hff')
            var rtnACL = __assign({}, (_a = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _a === void 0 ? void 0 : _a.access);
            console.log(ACLData.access, '==ff', rtnACL);
            setSelectedViewUser(__assign(__assign({}, ACLData.access), rtnACL));
            console.log('==hhh8', rtnACL);
            setOpenLeftPanel(true);
        }).catch(function (error) {
            // Handle any errors that occurred
            setSelectedViewUser(__assign({}, ACLData.access)); //[back] [0815] remove this
            console.error(error);
        });
    };
    var searchFilterGroup = function () {
        var _a;
        setIsLoading(true);
        var sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
        // update the filter labels
        // let updatedFilters = JSON.parse(JSON.stringify(filters));
        // if (updatedFilters?.educationLevel) {
        //     updatedFilters.educationLevel = EducationLevelMuiChoices[filters.educationLevel].label
        // }
        // if (updatedFilters?.gender) {
        //     updatedFilters.gender = GenderMuiChoices[filters.gender].label
        // }
        var params = {
            pagination: { page: 1, perPage: 25 },
            sort: { field: "id", order: "ASC" },
            // filter: updatedFilters //[back][0715] resume this
            filter: __assign(__assign({}, filters), sessonStorageJSON)
        };
        console.log('==log params on subject list', params);
        dataProvider.getList(resource, params)
            .then(function (data) {
            refresh();
            notify('Users fetched');
            setIsLoading(false);
        }).catch(function (error) {
            // Handle any errors that occurred
            console.error(error);
            setIsLoading(false);
        });
    };
    return (_jsxs(Grid, { container: true, style: styles.UserList, children: [_jsx(Grid, { item: true, xs: 12, children: _jsxs(Grid, { container: true, children: [openLeftPanel && (_jsx(Grid, { item: true, xs: 3, children: _jsx(SelectedRowLeftColumn, { acessControlList: acessControlList, translate: translate, selectedRow: selectedRow, setSelectedRow: setSelectedRow, openEditDialog: leftPanelOpenEditDialog, setOpenLeftPanel: setOpenLeftPanel, closeLeftPanel: closeLeftPanel, selectedViewUser: selectedViewUser }) })), _jsxs(Grid, { item: true, xs: openLeftPanel ? 9 : 12, children: [_jsx("div", { style: { marginTop: '0.5em' }, children: _jsx(general.breadcrumb, { trail: ["User Management", "".concat(translate("pages.userList.name"))] }) }), _jsxs(Grid, { item: true, display: "flex", alignItems: "center", justifyContent: "start", children: [_jsx(Typography, { variant: "h5", sx: {
                                                paddingRight: '15px',
                                                fontWeight: "900",
                                                marginBottom: '0.3em',
                                            }, children: translate("pages.userList.listName") }), acessControlList.subject.create &&
                                            _jsxs(Typography, { variant: "body", sx: {
                                                    color: "#1976d2",
                                                    cursor: "pointer",
                                                    fontWeight: "900",
                                                    marginBottom: '0.5em',
                                                    transition: 'all 0.3s ease',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    '&:hover': {
                                                        transform: 'scale(1.1) translateX(10px)',
                                                    },
                                                    '&:hover .MuiSvgIcon-root': {
                                                        transform: 'scale(1.1)',
                                                    }
                                                }, onClick: openEditDialog, children: [_jsx(AddBoxIcon, { sx: { marginRight: '0.3em', transition: 'all 0.3s ease' } }), translate("pages.userList.addNew")] })] }), _jsx(FilterList, { translate: translate, search: searchFilterGroup, isLoading: isLoading, params: params, filters: filters, setFilters: setFilters }), _jsx(TableDatagrid, { props: resource, data: data, setData: setData, setOrigDialogData: setOrigDialogData, translate: translate, setOpenLeftPanel: setOpenLeftPanel, getCurrentViewingUser: getCurrentViewingUser, acessControlList: acessControlList, selectedRow: selectedRow, setSelectedRow: setSelectedRow, openEditDialog: openEditDialog, filters: filters, setFilters: setFilters, isLoading: isLoading, setIsLoading: setIsLoading, delAction: delAction })] })] }) }), dialogOpened && _jsx(EditDialog, { opened: dialogOpened, data: dialogData, closeDialog: closeDialog, reloadRecord: reloadRecord, translate: translate, params: params })] }));
};
export default UserList;
