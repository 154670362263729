var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, CardActions, CircularProgress, Grid, Typography, } from '@mui/material';
import { required, TextInput, useTranslate } from 'react-admin';
import Box from '@mui/material/Box';
// import LoginLogo from '../assets/logos/login logo.png'
import ICSLogo from '../../assets/logos/ics logo.png';
var SignIn = function (_a) {
    var loading = _a.loading, signInInputs = _a.signInInputs, setSignInInputs = _a.setSignInInputs, setCurComp = _a.setCurComp;
    var translate = useTranslate();
    return (_jsx(Box, { sx: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'flex-start',
            background: '#ebeff2',
            backgroundSize: 'cover',
        }, children: _jsxs(Card, { sx: {
                minWidth: 300,
                maxWidth: 550,
                marginTop: '6em',
                borderRadius: "40px",
                boxShadow: '20px 20px 25px rgba(77, 106, 149, 0.5)', // Light blue color
            }, children: [_jsx(Box, { sx: {
                        margin: '1em',
                        display: 'flex',
                        justifyContent: 'center',
                    }, children: _jsx("img", { src: ICSLogo, alt: "logo", height: 90, style: {
                            transition: 'transform 0.3s ease-in-out',
                        }, onMouseEnter: function (e) {
                            e.currentTarget.style.transform = 'scale(1.5)';
                        }, onMouseLeave: function (e) {
                            e.currentTarget.style.transform = 'scale(1)';
                        } }) }), _jsx(Box, { sx: {
                        marginTop: '1em',
                        display: 'flex',
                        flexWrap: "wrap",
                        justifyContent: 'center',
                        color: function (theme) { return theme.palette.grey[500]; },
                    }, children: _jsxs(Grid, { container: true, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", style: { backgroundColor: "", width: "30rem" }, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "h4", textAlign: "center", color: "black", fontWeight: "1000", children: "AI Screening Platform" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "h5", textAlign: "center", color: "black", fontWeight: "400", children: "Sign In" }) })] }) }), _jsxs(Box, { sx: { padding: '0 1em 1em 1em' }, children: [_jsxs(Box, { sx: { marginTop: '1em' }, children: [_jsx(Typography, { variant: "body1", color: "#4d6a95", fontSize: "0.75em", fontWeight: "1000", sx: { margin: '0px 0px 0px 10px' }, children: "Email" }), _jsx(TextInput, { autoFocus: true, variant: 'outlined', source: "username", label: "Your Email", onChange: function (e) {
                                        setSignInInputs(__assign(__assign({}, signInInputs), { username: e.target.value }));
                                    }, disabled: loading, validate: required(), fullWidth: true, sx: {
                                        '& .MuiInputBase-root': {
                                            borderRadius: '10px',
                                            border: '1px solid #ccc',
                                            backgroundColor: "white",
                                            height: "65px",
                                            '&:focus-within': {
                                                backgroundColor: "white",
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'rgba(0, 0, 0, 0.3)',
                                            fontSize: "0.95em"
                                        },
                                    } })] }), _jsxs(Box, { sx: { marginTop: '1em' }, children: [_jsx(Typography, { variant: "body1", color: "#4d6a95", fontSize: "0.75em", fontWeight: "1000", sx: { margin: '0px 0px 0px 10px' }, children: "Password" }), _jsx(TextInput, { variant: 'outlined', source: "password", label: "Password", type: "password", disabled: loading, onChange: function (e) {
                                        setSignInInputs(__assign(__assign({}, signInInputs), { password: e.target.value }));
                                    }, validate: required(), fullWidth: true, sx: {
                                        '& .MuiInputBase-root': {
                                            borderRadius: '10px',
                                            border: '1px solid #ccc',
                                            backgroundColor: "white",
                                            height: "65px",
                                            '&:focus-within': {
                                                backgroundColor: "white",
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'rgba(0, 0, 0, 0.3)',
                                            fontSize: "0.95em"
                                        },
                                    } })] })] }), _jsx(CardActions, { sx: { padding: '0 1em 1em 1em' }, children: _jsxs(Button, { variant: "contained", type: "submit", color: "primary", disabled: loading, fullWidth: true, sx: {
                            backgroundColor: "#4d6a95",
                            borderRadius: "10px",
                            fontSize: "1.4rem",
                            position: "relative",
                            overflow: "hidden",
                            transition: "all 0.3s ease-in-out",
                            "&:before": {
                                content: '""',
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                width: "0%",
                                height: "0%",
                                backgroundColor: "rgba(255, 255, 255, 0.2)",
                                borderRadius: "50%",
                                transition: "all 0.5s ease-out",
                            },
                            "&:hover": {
                                transform: "translateY(-5px)",
                                boxShadow: "0 10px 20px rgba(77, 106, 149, 0.4)",
                                "&:before": {
                                    width: "300%",
                                    height: "300%",
                                    top: "-150%",
                                    left: "-150%",
                                },
                            },
                            "&:active": {
                                transform: "translateY(2px)",
                            }
                        }, children: [console.log(loading, '==loading'), loading && (_jsx(CircularProgress, { size: 25, thickness: 2 })), translate('login.signIn')] }) }), _jsx(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", padding: 2, children: _jsx(Typography, { variant: "body1", textAlign: "center", color: "#4d6a95", style: { cursor: "pointer" }, onClick: function () { setCurComp("forgetPW"); }, fontSize: "0.85em", fontWeight: '1000', sx: { margin: '0px 25px 70px 0px' }, children: "Forget your password?" }) })] }) }));
};
export default SignIn;
