import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form as RAForm, DateInput as RADateInput, minValue as RAminValue, } from 'react-admin';
import { Typography, Grid, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
var StyledCheckbox = styled(Checkbox)(function (_a) {
    var theme = _a.theme;
    return ({
        opacity: 0.3,
        '&:hover': {
            opacity: 1,
            transform: 'scale(1.1)',
            transition: 'all 0.3s ease-in-out',
            boxShadow: '0 0 10px rgba(0, 0, 255, 0.5)',
        },
        '&.Mui-checked': {
            opacity: 1,
            color: theme.palette.primary.main,
        },
    });
});
var ExportTextCheckBox = function (_a) {
    var name = _a.name, label = _a.label, labelRed = _a.labelRed, handleChange = _a.handleChange, checkBoxType = _a.checkBoxType, checked = _a.checked;
    //checkBoxType: alone, parent, child
    return (_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", marginLeft: checkBoxType === "child" ? "2rem" : "", style: { backgroundColor: "" }, children: [_jsx(StyledCheckbox, { name: name, onChange: function (e) { handleChange(e.target.checked, name, checkBoxType); }, checked: checked }), _jsx(Typography, { children: label }), labelRed && _jsx(Typography, { style: { color: "red", paddingLeft: "10px" }, children: "(".concat(labelRed, ")") })] }));
};
var ExportDateCheckBox = function (_a) {
    var name = _a.name, label = _a.label, dateFromName = _a.dateFromName, dateToName = _a.dateToName, dateFromLabel = _a.dateFromLabel, dateToLabel = _a.dateToLabel, handleChange = _a.handleChange, defaultFromDate = _a.defaultFromDate, defaulToDate = _a.defaulToDate, handleChangeDate = _a.handleChangeDate, checkBoxType = _a.checkBoxType, checked = _a.checked;
    //checkBoxType: alone, parent, child
    return (_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", children: [_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", marginLeft: checkBoxType === "child" ? "2rem" : "", xs: 12, style: { backgroundColor: "" }, children: [_jsx(StyledCheckbox, { name: name, onChange: function (e) {
                            handleChange(e.target.checked, name, checkBoxType);
                        }, checked: checked }), _jsx(Typography, { sx: { fontWeight: 900 }, children: label })] }), _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", marginLeft: 5, children: [_jsx(Grid, { item: true, paddingRight: 5, children: _jsx(RAForm, { paddingX: 3, children: _jsx(RADateInput, { source: dateFromName, label: dateFromLabel, validate: RAminValue(new Date('2024-06-06')), onChange: function (e) {
                                    handleChangeDate(new Date(e.target.value).toLocaleDateString('en-US'), name, dateFromName);
                                }, defaultValue: defaultFromDate ? new Date(defaultFromDate) : null, sx: {
                                    opacity: 0.45,
                                    width: "9em",
                                    '& .MuiInputBase-root': {
                                        borderTopLeftRadius: '20px',
                                        borderTopRightRadius: '20px',
                                        boxShadow: '0px 6px 8px gray',
                                        height: "2.7em",
                                        fontWeight: 500,
                                        fontSize: "1em",
                                    },
                                    '& .MuiFormLabel-root': {},
                                } }) }) }), _jsx(RAForm, { children: _jsx(RADateInput, { source: dateToName, label: dateToLabel, validate: RAminValue(new Date('2024-06-06')), onChange: function (e) {
                                handleChangeDate(e.target.value, name, dateToName);
                            }, defaultValue: defaulToDate ? new Date(defaulToDate) : null, sx: {
                                opacity: 0.45,
                                width: "9em",
                                '& .MuiInputBase-root': {
                                    borderTopLeftRadius: '20px',
                                    borderTopRightRadius: '20px',
                                    boxShadow: '0px 6px 8px gray',
                                    height: "2.7em",
                                    fontWeight: 500,
                                    fontSize: "1em",
                                },
                                '& .MuiFormLabel-root': {},
                            } }) })] })] }));
};
export { ExportTextCheckBox, ExportDateCheckBox };
