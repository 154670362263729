import leftEye from '../../assets/dummyImages/leftEye.png';
import samplePDF from '../../assets/SM24-08-AISP FP Report Example-V0.1.pdf';
var dummyDataExport = [
    {
        "subject": {
            "id": "3f187fb2-9d46-443f-9949-89588bff66b0",
            "hkid": "h881187667",
            "firstname": "TEST",
            "lastname": "wong",
            "gender": "F",
            "educationLevel": "7-9 yrs",
            "dateOfBirth": "2024-07-24T02:35:27.000Z"
        },
        "fp_imgs": [
            {
                "id": "f2f5b368-98bd-4a9c-83b9-8816a1cdc850",
                "exam_id": "d9941501-9410-4700-95b0-baf3e0659fb8",
                "comment": null,
                // "img": "/uploads\\images-1721702541384-226359782.png",
                // "img": "https://aisp.dev.hkrnd.com/api/uploads/1723082273689-915745331-Screenshot 2023-08-20 183939.png",
                "img": leftEye,
                "original_name": null,
                "center": false,
                "right": true,
                "gradable": true,
                "createdAt": "2024-07-22T18:42:23.135Z",
                "updatedAt": "2024-07-24T02:28:12.000Z",
                "deletedAt": null
            },
            {
                "id": "f2f5b368-98bd-4a9c-83b9-8816a1cdc850",
                "exam_id": "d9941501-9410-4700-95b0-baf3e0659fb8",
                "comment": null,
                // "img": "/uploads\\images-1721702541384-226359782.png",
                "img": leftEye,
                // "img": "https://aisp.dev.hkrnd.com/api/uploads/1723082273689-915745331-Screenshot 2023-08-20 183939.png",
                "original_name": null,
                "center": false,
                "right": true,
                "gradable": true,
                "createdAt": "2024-07-22T18:42:23.135Z",
                "updatedAt": "2024-07-24T02:28:12.000Z",
                "deletedAt": null
            }
        ],
        "questionaire_results": [
            {}
        ],
        "retinADReports": {
            "fp": [
                "/uploads/1723451663694-449397384-SM24-08-AISP_FP_Report_Example-V0.1.pdf"
            ],
            "oct": [
                { pdf: samplePDF, name: "xxx" }
            ],
            "fpAndOct": [
                {}
            ]
        }
    }
];
var dummyPdfData = {
    "idx": {
        fp: [
            {
                "analysisData": {
                    "average_of_fold": 0.9970219379999999,
                    "avg_score": 0.2978062000000059,
                    "confidence_level": "≈90%",
                    "folds": {
                        "fold1": 0.99701154,
                        "fold2": 0.99987555,
                        "fold3": 0.9997745,
                        "fold4": 0.9884493,
                        "fold5": 0.9999988
                    },
                    "folds_pred": {
                        "fold1_pred": 0,
                        "fold2_pred": 0,
                        "fold3_pred": 0,
                        "fold4_pred": 0,
                        "fold5_pred": 0
                    },
                    "mode": "BE",
                    "reverse": 0.002978062000000059,
                    "risk_of_AD": "Low",
                    "score1": 16.1,
                    "score2": 50.2,
                    "sum_of_folds_pred": 0
                },
                "examData": {
                    "FP": {
                        "gradable": {
                            "maculaCentered": {
                                "left": {
                                    "comment": null,
                                    "img": "leftEye"
                                },
                                "right": {
                                    "comment": null,
                                    "img": "leftEye"
                                }
                            },
                            "notMaculaCentered": {
                                "left": {
                                    "comment": null,
                                    "img": "leftEye"
                                },
                                "right": {
                                    "comment": null,
                                    "img": "leftEye"
                                }
                            }
                        }
                    },
                    "examId": "123",
                    "subjectId": "abc"
                },
                "messages": [],
                "result": true
            }
        ],
        oct: [],
        fpAndOct: []
    }
};
export { dummyDataExport, dummyPdfData };
