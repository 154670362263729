import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useState } from 'react';
import { subjectIdHelper, longNameHelper, } from '../utils/helper';
import { DatagridConfigurable, DateField, ExportButton, List, SelectColumnsButton, TextField, TopToolbar, WrapperField, FunctionField, Confirm, } from 'react-admin';
import { useMediaQuery, Typography, Grid, IconButton, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
var ListActions = function () { return (_jsxs(TopToolbar, { children: [_jsx(SelectColumnsButton, {}), _jsx(ExportButton, {})] })); };
var TableDatagrid = function (_a) {
    var setOrigDialogData = _a.setOrigDialogData, acessControlList = _a.acessControlList, translate = _a.translate, setSelectedRow = _a.setSelectedRow, data = _a.data, setData = _a.setData, setSelectedColumn = _a.setSelectedColumn, selectedColumn = _a.selectedColumn, setDialogData = _a.setDialogData, dialogData = _a.dialogData, dialogOpened = _a.dialogOpened, setDialogDataAction = _a.setDialogDataAction, closeDialog = _a.closeDialog, educationLevelArray = _a.educationLevelArray, openEditDialog = _a.openEditDialog, dataProvider = _a.dataProvider, refresh = _a.refresh, notify = _a.notify, resource = _a.resource, navigate = _a.navigate, sort = _a.sort, setOpenLeftPanel = _a.setOpenLeftPanel, filters = _a.filters, setFilters = _a.setFilters, getCurrentViewingUser = _a.getCurrentViewingUser, delAction = _a.delAction;
    // const { filterValues, setFilters, displayedFilters, sort } = listContext;
    console.log(sort, '==listContext');
    var isXSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm');
    });
    // const dispatch = useDispatch()
    var _b = useState(false), confirmOpen = _b[0], setConfirmOpen = _b[1];
    var _c = useState(null), recordToDelete = _c[0], setRecordToDelete = _c[1];
    var handleDelete = function (record) {
        setRecordToDelete(record);
        setConfirmOpen(true);
    };
    var handleConfirm = function () {
        if (recordToDelete) {
            delAction(recordToDelete);
        }
        setConfirmOpen(false);
    };
    var handleCancel = function () {
        setConfirmOpen(false);
    };
    return (_jsxs(Fragment, { children: [_jsx(List
            // filterDefaultValues={{ status: 'ordered' }}
            , { 
                // filterDefaultValues={{ status: 'ordered' }}
                filterDefaultValues: filters, sort: { field: 'id', order: 'DESC' }, 
                // debounce={handleListChange}
                // perPage={25}
                empty: false, 
                // filters={orderFilters}
                actions: _jsx(ListActions, {}), children: _jsxs(DatagridConfigurable, { bulkActionButtons: false, children: [_jsx(FunctionField, { source: "id", label: translate("pages.userList.fields.id"), render: function (record) {
                                return (_jsx(Typography, { children: subjectIdHelper(record.id) }));
                            } }), _jsx(FunctionField, { source: "organizations", label: translate("pages.userList.fields.organization"), render: function (record) {
                                var _a;
                                return (_jsx(Typography, { style: {}, children: longNameHelper((_a = record === null || record === void 0 ? void 0 : record.current_organization) === null || _a === void 0 ? void 0 : _a.orgName) }));
                            } }), _jsx(FunctionField, { source: "userGroup", label: translate("pages.userList.fields.userGroup"), render: function (record) {
                                var _a;
                                return (_jsxs(Typography, { style: {}, children: [console.log(record.userGroups, '==log userGroup '), (_a = record.userGroups) === null || _a === void 0 ? void 0 : _a.map(function (item) { return item.name; }).join(', ')] }));
                            } }), _jsx(TextField, { source: "username", label: translate("pages.userList.fields.username") }), _jsx(TextField, { source: "Last_name", label: translate("pages.subject.fields.lastname") }), _jsx(TextField, { source: "First_name", label: translate("pages.subject.fields.firstname") }), _jsx(TextField, { source: "email", label: translate("pages.userList.fields.email") }), _jsx(TextField, { source: "gender", label: translate("pages.subject.fields.gender") }), _jsx(FunctionField, { source: "status", label: translate("pages.userList.fields.status"), render: function (record) {
                                var _a;
                                var bgColor;
                                var hoverColor;
                                switch (record.status) {
                                    case ("Disabled" || "disabled"):
                                        bgColor = "red";
                                        hoverColor = "#FF6347"; // Tomato red
                                        break;
                                    default:
                                        bgColor = "green";
                                        hoverColor = "#4CAF50"; // Lighter green
                                        break;
                                }
                                return (_jsxs(_Fragment, { children: [_jsx(Button, { className: "status-button", style: {
                                                backgroundColor: bgColor,
                                                color: "white",
                                                padding: 0,
                                                textTransform: 'none'
                                            }, children: (_a = record.status) !== null && _a !== void 0 ? _a : "Active" }), _jsx("style", { jsx: true, children: "\n                                        .status-button {\n                                            transition: all 0.3s ease;\n                                        }\n                                        .status-button:hover {\n                                            background-color: ".concat(hoverColor, " !important;\n                                            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n                                            transform: scale(1.05);\n                                        }\n                                    ") })] }));
                            } }), _jsx(DateField, { source: "createdAt", label: translate("pages.userList.fields.createdAt") }), _jsx(WrapperField, { label: translate("pages.subject.fields.actions"), children: _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", children: [_jsx(FunctionField, { source: "", label: "", render: function (record) {
                                            return (_jsx(Grid, { item: true, onClick: function () {
                                                    // openEditDialog(record)
                                                    // setDialogDataAction(record)
                                                    console.log('==logggg', record);
                                                    setSelectedRow(record);
                                                    getCurrentViewingUser(record.id);
                                                    // navigate('/admin/exams', { state: record })
                                                }, children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(PreviewIcon, { fontSize: "small" }) }) }));
                                        } }), acessControlList.subject.edit && _jsx(FunctionField, { source: "", label: "", render: function (record) {
                                            return (_jsx(Grid, { item: true, onClick: function () {
                                                    openEditDialog(record);
                                                }, children: _jsx(Tooltip, { title: "edit user", children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(EditIcon, { fontSize: "small" }) }) }) }));
                                        } }), acessControlList.subject.delete && _jsx(FunctionField, { source: "", label: "", render: function (record) {
                                            return (_jsx(Grid, { item: true, onClick: function () { handleDelete(record); }, children: _jsx(Tooltip, { title: "delete user", children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(DeleteIcon, { fontSize: "small" }) }) }) }));
                                        } })] }) })] }) }), _jsx(Confirm, { isOpen: confirmOpen, title: "Delete User", content: "Are you sure you want to delete this user?", onConfirm: handleConfirm, onClose: handleCancel })] }));
};
export default TableDatagrid;
