var styles = {
    documentBlock: {
        width: 160,
        height: 200,
        margin: "20px auto",
        marginTop: 8,
        // overflow: "hidden"
    },
    controls: {
        maxWidth: 160,
        margin: "0 auto",
        marginTop: 8,
    },
    container: {
        width: "100%",
        height: "65vh",
        // backgroundColor: "#fafbfc"
        backgroundColor: "white",
        // backgroundColor: "red",
        // overflowY: "scroll"
    },
    containerAfterAnlyze: {
        width: "100%",
        height: "50rem",
        // backgroundColor: "green",
        // overflowY: "scroll"
    },
    anaylzedText: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        padding: "5px 10px",
        color: "white",
        borderRadius: "20px"
    },
    withBorder: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "start",
        margin: "0.5rem 0",
        width: "100%"
        // border: "1px solid grey"
    },
    droppableGrid: {
        display: "flex",
        flexWrap: "wrap",
        // alignItems: "start",
        margin: "0.5rem 0",
        width: "100%",
        alignItems: "space-between"
    },
    droppableOuterGrid: {
        display: "flex",
        flexWrap: "wrap",
        // alignItems: "start",
        margin: "0.5rem 0",
        width: "100%",
        alignItems: "space-between",
        justifyContent: "center",
        alignContent: "start"
    },
    titleWithBorder: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        margin: "0.5rem 0",
        // border: "1px solid grey"
    },
    imageWithBorder: {
        display: "flex",
        flexWrap: "wrap",
        // alignItems: "center",
        backgroundColor: "white",
        padding: "1rem"
    },
    loadingContainer: {
        textAlign: "center",
        padding: 20,
        marginTop: 12,
        color: 'black',
        fontSize: 18,
        fontWeight: 600,
        borderRadius: 4,
        userSelect: "none",
        outline: 0,
        cursor: "pointer",
        width: "100%",
        // backgroundColor: "blue"
    },
};
export { styles };
