import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslate, } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import PageviewIcon from '@mui/icons-material/Pageview';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { dbDateHelper, blueIDHelper } from '../utils/helper';
var Spacer = function () { return _jsx(Box, { mb: 1, children: "\u00A0" }); };
var RowItem = function (_a) {
    var leftText = _a.leftText, rightText = _a.rightText;
    return (_jsxs(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignContent: "center", justifyContent: "space-between", paddingX: 2, marginY: 0.5, children: [_jsx(Typography, { variant: "body", children: leftText }), _jsx(Typography, { variant: "body", children: rightText })] }));
};
var RowItemTitle = function (_a) {
    var title = _a.title;
    return (_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "h6", style: { color: "black", textAlign: "center", fontWeight: "600" }, children: title }) }));
};
var SelectedRowLeftColumn = function (_a) {
    var selectedRow = _a.selectedRow, setSelectedRow = _a.setSelectedRow, data = _a.data, openExamEditDialog = _a.openExamEditDialog, setDialogOpened = _a.setDialogOpened, closeLeftPanel = _a.closeLeftPanel, page = _a.page, handlePreviewExamDate = _a.handlePreviewExamDate;
    var translate = useTranslate();
    var navigate = useNavigate();
    console.log(selectedRow, '==selectedRow 123');
    return (_jsxs(Grid, { container: true, spacing: 1, padding: 4, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", sx: {
            position: 'sticky',
            top: 0,
            zIndex: 1,
            marginTop: "0.4em",
            marginLeft: "10px",
            width: "90%",
            padding: "0.3em",
            backgroundColor: 'white', // Add a background color to ensure content below doesn't show through
        }, children: [_jsx(SubjectTitle, { setSelectedRow: setSelectedRow, navigate: navigate, setDialogOpened: setDialogOpened, closeLeftPanel: closeLeftPanel, page: page }), _jsxs(Grid, { item: true, xs: 12, sx: {
                    boxShadow: '0 0 5px gray',
                    borderRadius: '15px',
                    fontWeight: "450",
                    fontSize: '0.9em'
                }, children: [_jsx(RowItemTitle, { title: "Subject Information" }), _jsx(RowItem, { leftText: "Subject ID", rightText: blueIDHelper(selectedRow.hkid) }), _jsx(RowItem, { leftText: "Last name", rightText: selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.lastname }), _jsx(RowItem, { leftText: "First name", rightText: selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.firstname }), _jsx(RowItem, { leftText: "Gender", rightText: selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.gender }), _jsx(RowItem, { leftText: "Education lv.", rightText: selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.educationLevel }), _jsx(RowItem, { leftText: "Date of birth", rightText: dbDateHelper(selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.dateOfBirth) })] }), _jsxs(Grid, { item: true, xs: 12, marginY: 2, sx: {
                    boxShadow: '0 0 5px gray',
                    borderRadius: '15px',
                    marginTop: "1em",
                }, children: [_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", children: [_jsx(RowItemTitle, { title: "All Exam Date" }), _jsx(Box, { display: "flex", justifyContent: "flex-end", width: "100%", paddingRight: 2, children: page === "exams" && (_jsxs(Box, { sx: {
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: "#1976d2",
                                        cursor: "pointer",
                                        fontWeight: 500,
                                        opacity: "0.6",
                                        fontSize: "1em",
                                        transition: 'all 0.3s',
                                        '&:hover': {
                                            transform: 'scale(1.1)',
                                        },
                                    }, onClick: openExamEditDialog, children: [_jsx(AddBoxIcon, { sx: { marginRight: '0.3em', fontSize: '1.2em' } }), _jsx(Typography, { sx: { fontSize: '0.8em', fontWeight: 900 }, children: "Add New Exam Date" })] })) })] }), data && data.map(function (el, idx) {
                        console.log('==data for dates', data);
                        return (_jsx(RowItem, { leftText: dbDateHelper(el.exam_date), rightText: _jsx(Grid, { item: true, style: { backgroundColor: "", color: "#487ca8" }, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: page === "exams" && _jsx(PageviewIcon, { onClick: function () { handlePreviewExamDate(el); } }) }) }));
                    })] })] }));
};
var SubjectTitle = function (_a) {
    var setSelectedRow = _a.setSelectedRow, navigate = _a.navigate, setDialogOpened = _a.setDialogOpened, closeLeftPanel = _a.closeLeftPanel, page = _a.page;
    var translate = useTranslate();
    return (_jsxs(Grid, { item: true, xs: 12, display: "flex", alignItems: "center", justifyContent: "space-between", children: [_jsx(Typography, { variant: "h6", sx: { fontWeight: "800" }, children: "View Subject" }), _jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [_jsxs(Box, { sx: {
                            display: 'flex',
                            alignItems: 'center',
                            color: "#1976d2",
                            cursor: "pointer",
                            fontWeight: "600",
                            opacity: "0.7",
                            fontSize: "0.8em",
                            padding: "4px 8px",
                            borderRadius: "5px",
                            transition: "all 0.3s",
                            '&:hover': {
                                backgroundColor: "rgba(25, 118, 210, 0.1)",
                                '& .editIcon': {
                                    transform: 'scale(1.3)',
                                },
                                '& .editText': {
                                    fontSize: '1.1em',
                                }
                            }
                        }, onClick: function () { setDialogOpened(true); }, children: [_jsx(EditIcon, { className: "editIcon", fontSize: "small", sx: { marginRight: 0.5, transition: "transform 0.3s" } }), _jsx("span", { className: "editText", style: { transition: "font-size 0.3s" }, children: "Edit" })] }), _jsxs(Box, { sx: {
                            display: 'flex',
                            alignItems: 'center',
                            color: "#1976d2",
                            cursor: "pointer",
                            fontWeight: "600",
                            opacity: "0.7",
                            fontSize: "0.8em",
                            borderRadius: "5px",
                            transition: "all 0.3s",
                            '&:hover': {
                                backgroundColor: "rgba(25, 118, 210, 0.1)",
                                '& .closeIcon': {
                                    transform: 'rotate(90deg) scale(1.3)',
                                },
                                '& .closeText': {
                                    fontSize: '1.1em',
                                }
                            }
                        }, onClick: function () {
                            setSelectedRow(false);
                            navigate('/admin/subjects');
                            if (closeLeftPanel) {
                                closeLeftPanel();
                            }
                        }, children: [_jsx(CloseIcon, { className: "closeIcon", fontSize: "small", sx: { marginRight: 0.5, transition: "transform 0.3s" } }), _jsx("span", { className: "closeText", style: { transition: "font-size 0.3s" }, children: "Close" })] })] })] }));
};
export default SelectedRowLeftColumn;
