var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EditDialog from './EditDialog';
import { DatagridConfigurable, DateField, ExportButton, 
// FilterButton,
List, SelectColumnsButton, TextField, TopToolbar, WrapperField, FunctionField, useDataProvider, useResourceContext, useNotify, useRefresh, useTranslate, useListController, Confirm, } from 'react-admin';
// import dataProviderFactory from '../demoServerAndData/dataProvider';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import PreviewIcon from '@mui/icons-material/Preview';
import { GenderMuiChoices, EducationLevelMuiChoices } from './Params';
import { useMediaQuery, Divider, Typography, Grid, Button, IconButton } from '@mui/material';
import { blueIDHelper } from '../utils/helper';
import { general } from '../utils/componentWizard';
import FilterList from './FilterList';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useDispatch } from 'react-redux';
import { subjectIdHelper } from '../utils/helper';
import SelectedRowLeftColumn from '../examsPage/SelectedRowLeftColumn';
import { ACLData } from '../utils/dummyData';
var ListActions = function () { return (_jsxs(TopToolbar, { children: [_jsx(SelectColumnsButton, {}), _jsx(ExportButton, {})] })); };
var SubjectList = function (props) {
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(ACLData), acessControlList = _b[0], setAcessControlList = _b[1];
    var _c = useState({
    // analyzeFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // analyzeToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    }), filters = _c[0], setFilters = _c[1];
    var _d = useState({ gender: GenderMuiChoices, educationLevel: EducationLevelMuiChoices }), params = _d[0], setParams = _d[1];
    var educationLevelArray = ["0-3 yrs", "4-6 yrs", "7-9 yrs", "10-12 yrs", "> 12 yrs"];
    var _e = useState([]), data = _e[0], setData = _e[1];
    var _f = useState(false), selectedColumn = _f[0], setSelectedColumn = _f[1];
    var _g = useState(), dialogData = _g[0], setDialogData = _g[1];
    var _h = useState(false), dialogOpened = _h[0], setDialogOpened = _h[1];
    var dataProvider = useDataProvider();
    var resource = useResourceContext(props);
    var notify = useNotify();
    var refresh = useRefresh();
    var navigate = useNavigate();
    // const listContext = useListContext();
    var translate = useTranslate();
    // for left panel
    var _j = useState(), selectedRow = _j[0], setSelectedRow = _j[1];
    var _k = useState(false), openLeftPanel = _k[0], setOpenLeftPanel = _k[1];
    var _l = useState([]), examsData = _l[0], setExamsData = _l[1];
    var sort = useListController().sort;
    console.log('==listController', sort);
    // document.body.style.zoom = "100%";
    useEffect(function () {
        var _a, _b;
        var sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
        if (sessonStorageJSON) {
            setFilters(__assign(__assign({}, filters), sessonStorageJSON));
        }
        sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(__assign(__assign({}, filters), sessonStorageJSON)));
        searchFilterGroup();
        // get ACL
        var cachedAuth = (_b = JSON.parse(localStorage.getItem('auth'))) !== null && _b !== void 0 ? _b : {};
        var settingsParams = {};
        if (cachedAuth) {
            settingsParams.id = cachedAuth.id;
            dataProvider.getOneWithoutDataJSON("users", settingsParams)
                .then(function (rtnData) {
                var _a;
                console.log(rtnData, '==log settings data 321');
                var rtnACL = __assign({}, (_a = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _a === void 0 ? void 0 : _a.access);
                var newACList = __assign({}, ACLData);
                newACList.access = __assign({}, rtnACL);
                setAcessControlList(newACList);
            }).catch(function (error) {
                // Handle any errors that occurred
                console.error(error);
            });
        }
    }, []);
    useEffect(function () {
        console.log(' ');
    }, [selectedRow]);
    // filter group when "search"
    var searchFilterGroup = function () {
        var _a;
        setIsLoading(true);
        var sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
        // update the filter labels
        // let updatedFilters = JSON.parse(JSON.stringify(filters));
        // if (updatedFilters?.educationLevel) {
        //     updatedFilters.educationLevel = EducationLevelMuiChoices[filters.educationLevel].label
        // }
        // if (updatedFilters?.gender) {
        //     updatedFilters.gender = GenderMuiChoices[filters.gender].label
        // }
        var params = {
            pagination: { page: 1, perPage: 25 },
            sort: { field: "id", order: "ASC" },
            // filter: updatedFilters //[back][0715] resume this
            filter: __assign(__assign({}, filters), sessonStorageJSON)
        };
        console.log('==log params on subject list', params);
        dataProvider.getList(resource, params)
            .then(function (data) {
            refresh();
            notify('Subject fetched');
            setIsLoading(false);
        }).catch(function (error) {
            // Handle any errors that occurred
            console.error(error);
            setIsLoading(false);
        });
    };
    var closeDialog = function () {
        setDialogOpened(false);
    };
    var setDialogDataAction = function (record) {
        var _a, _b, _c, _d, _e, _f, _g;
        setDialogData({
            submit: null,
            dialogType: (record === null || record === void 0 ? void 0 : record.id) ? "Edit" : "Create",
            id: (_a = record === null || record === void 0 ? void 0 : record.id) !== null && _a !== void 0 ? _a : null,
            hkid: (_b = record === null || record === void 0 ? void 0 : record.hkid) !== null && _b !== void 0 ? _b : null,
            lastname: (_c = record === null || record === void 0 ? void 0 : record.lastname) !== null && _c !== void 0 ? _c : null,
            firstname: (_d = record === null || record === void 0 ? void 0 : record.firstname) !== null && _d !== void 0 ? _d : null,
            gender: (_e = record === null || record === void 0 ? void 0 : record.gender) !== null && _e !== void 0 ? _e : null,
            educationLevel: (_f = record === null || record === void 0 ? void 0 : record.educationLevel) !== null && _f !== void 0 ? _f : null,
            dateOfBirth: (_g = record === null || record === void 0 ? void 0 : record.dateOfBirth) !== null && _g !== void 0 ? _g : null,
        });
    };
    var openEditDialog = function (record) {
        console.log('==log record', record);
        setDialogDataAction(record);
        // formik?.current?.resetForm();
        setDialogOpened(true);
    };
    var reloadRecord = function (values, dialogType) {
        try {
            console.log('==log submit from dialog', values, dialogType);
            var params_1 = {
                data: {
                    // id: values.id,
                    hkid: values.hkid, //[back] later switch to hkid
                    firstname: values.firstname,
                    lastname: values.lastname,
                    gender: values.gender,
                    educationLevel: values.educationLevel,
                    dateOfBirth: values.dateOfBirth,
                    createby: 1,
                    givenId: values.id //[back] later switch to real id - id for db, not hkid
                }
            };
            switch (dialogType) {
                case "Create":
                    var updatedData = JSON.parse(JSON.stringify(data));
                    updatedData.push(values);
                    setData(updatedData);
                    dataProvider.create(resource, params_1)
                        .then(function (returnData) {
                        refresh();
                        notify('Subject added');
                    }).catch(function (error) {
                        // Handle any errors that occurred
                        console.error(error);
                        notify('Subject create failed');
                    });
                    break;
                case "Edit":
                    params_1.data.id = values.id;
                    dataProvider.update(resource, params_1)
                        .then(function () {
                        refresh();
                        notify('Subject updated');
                    }).catch(function (error) {
                        // Handle any errors that occurred
                        console.error(error);
                        notify('Subject update failed');
                    });
                    break;
            }
            setDialogData(__assign({}, values));
        }
        catch (err) {
            console.log(err, '==err');
            // dispatch(actionTypes.SNACKBAR_ERROR);
        }
    };
    var handleCloseLeftPanel = function () {
        setOpenLeftPanel(false);
    };
    var handleListChange = function () {
        console.log('==list cahnge', sort);
    };
    return (_jsxs(Grid, { container: true, display: "flex", children: [openLeftPanel && _jsx(Grid, { item: true, xs: 3, children: _jsx(SelectedRowLeftColumn, { selectedRow: dialogData, setSelectedRow: setDialogData, data: examsData, setDialogOpened: setDialogOpened, openExamEditDialog: openEditDialog, closeLeftPanel: handleCloseLeftPanel, page: resource }) }), _jsxs(Grid, { item: true, xs: openLeftPanel ? 9 : 12, sx: { marginTop: '1em' }, children: [_jsx(general.breadcrumb, { trail: ["Main", "".concat(translate("pages.subject.name"))] }), _jsxs(Grid, { item: true, display: "flex", alignItems: "center", justifyContent: "start", children: [_jsx(Typography, { variant: "h5", sx: {
                                    paddingRight: '15px',
                                    fontWeight: "900",
                                    marginBottom: '0.5em',
                                }, children: translate("pages.subject.name") }), acessControlList.subject.create &&
                                _jsxs(Typography, { variant: "body", sx: {
                                        color: "#1976d2",
                                        cursor: "pointer",
                                        fontWeight: "900",
                                        marginBottom: '0.5em',
                                        transition: 'all 0.3s ease',
                                        display: 'flex',
                                        alignItems: 'center',
                                        '&:hover': {
                                            transform: 'scale(1.1) translateX(10px)',
                                        },
                                        '&:hover .MuiSvgIcon-root': {
                                            transform: 'scale(1.1)',
                                        }
                                    }, onClick: openEditDialog, children: [_jsx(AddBoxIcon, { sx: { marginRight: '0.3em', transition: 'all 0.3s ease' } }), translate("pages.subject.addNew")] })] }), _jsx(Divider, {}), _jsx(FilterList, { translate: translate, search: searchFilterGroup, isLoading: isLoading, params: params, filters: filters, setFilters: setFilters }), _jsx(List
                    // filterDefaultValues={{ status: 'ordered' }}
                    , { 
                        // filterDefaultValues={{ status: 'ordered' }}
                        filterDefaultValues: filters, sort: { field: 'id', order: 'DESC' }, debounce: handleListChange, 
                        // perPage={25}
                        empty: false, 
                        // filters={orderFilters}
                        actions: _jsx(ListActions, {}), children: _jsx(TableDatagrid, { acessControlList: acessControlList, translate: translate, setSelectedRow: setSelectedRow, data: data, setData: setData, setSelectedColumn: setSelectedColumn, selectedColumn: selectedColumn, dialogData: dialogData, setDialogData: setDialogData, dialogOpened: dialogOpened, setDialogOpened: setDialogOpened, closeDialog: closeDialog, educationLevelArray: educationLevelArray, openEditDialog: openEditDialog, dataProvider: dataProvider, refresh: refresh, notify: notify, resource: resource, navigate: navigate, sort: sort, setDialogDataAction: setDialogDataAction, setOpenLeftPanel: setOpenLeftPanel, setExamsData: setExamsData }) }), openLeftPanel &&
                        _jsx(Grid, { display: "flex", justifyContent: "flex-end", children: _jsx(Button, { variant: "contained", onClick: function () { navigate('/admin/exams', { state: dialogData }); }, sx: {
                                    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                                    border: 0,
                                    marginBottom: '1em',
                                    borderRadius: 3,
                                    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                                    color: 'white',
                                    height: 48,
                                    padding: '0 30px',
                                    transition: 'all 0.3s ease-in-out',
                                    position: 'relative',
                                    overflow: 'hidden',
                                    '&:hover': {
                                        background: 'linear-gradient(45deg, #21CBF3 30%, #2196F3 90%)',
                                        transform: 'translateY(-3px) scale(1.05)',
                                        boxShadow: '0 6px 20px rgba(33, 203, 243, .5)',
                                    },
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        top: '-50%',
                                        left: '-50%',
                                        width: '200%',
                                        height: '200%',
                                        background: 'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0) 100%)',
                                        transform: 'rotate(30deg)',
                                        animation: 'shine 3s infinite linear',
                                    },
                                    '@keyframes shine': {
                                        '0%': { transform: 'translateX(-100%) rotate(30deg)' },
                                        '100%': { transform: 'translateX(100%) rotate(30deg)' },
                                    },
                                }, children: "Select" }) }), dialogOpened && _jsx(EditDialog, { opened: dialogOpened, data: dialogData, 
                        // openDialog={openDialog}
                        closeDialog: closeDialog, reloadRecord: reloadRecord, translate: translate })] })] }));
};
// const orderFilters = [
//     // <SearchInput source="q" alwaysOn />,
//     <ReferenceInput source="id" reference="id" />,
//     <TextField source="lastname" label="Last name" />,
//     <TextField source="firstname" label="First name" />,
//     <TextField source="gender" label="Gender" />,
//     <TextField source="educationLevel" label="Education lv." />,
//     <DateField source="dateOfBirth" label="Date of birth" />,
//     <NullableBooleanInput source="returned" />,
// ];
var TableDatagrid = function (_a) {
    var acessControlList = _a.acessControlList, translate = _a.translate, setSelectedRow = _a.setSelectedRow, data = _a.data, setData = _a.setData, setSelectedColumn = _a.setSelectedColumn, selectedColumn = _a.selectedColumn, setDialogData = _a.setDialogData, dialogData = _a.dialogData, dialogOpened = _a.dialogOpened, setDialogOpened = _a.setDialogOpened, setDialogDataAction = _a.setDialogDataAction, closeDialog = _a.closeDialog, educationLevelArray = _a.educationLevelArray, openEditDialog = _a.openEditDialog, dataProvider = _a.dataProvider, refresh = _a.refresh, notify = _a.notify, resource = _a.resource, navigate = _a.navigate, sort = _a.sort, setOpenLeftPanel = _a.setOpenLeftPanel, setExamsData = _a.setExamsData;
    // const { filterValues, setFilters, displayedFilters, sort } = listContext;
    console.log(sort, '==listContext');
    var isXSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm');
    });
    var dispatch = useDispatch();
    var handleDataTableChange = function () {
        console.log(sort, '==sort and table change', handleDataTableChange);
    };
    var delAction = function (record) {
        var params = {
            data: {
                id: record.id,
                hkid: record.hkid, //[back] later switch to hkid
                firstname: record.firstname,
                lastname: record.lastname,
                gender: record.gender,
                educationLevel: record.educationLevel,
                dateOfBirth: record.dateOfBirth,
                createby: 1,
                givenId: record.id //[back] later switch to real id - id for db, not hkid
            }
        };
        dataProvider.delete(resource, params)
            .then(function () {
            refresh();
            notify('Subject deleted');
        });
    };
    var _b = useState(false), confirmOpen = _b[0], setConfirmOpen = _b[1];
    var _c = useState(null), recordToDelete = _c[0], setRecordToDelete = _c[1];
    var handleDelete = function (record) {
        setRecordToDelete(record);
        setConfirmOpen(true);
    };
    var handleConfirm = function () {
        if (recordToDelete) {
            delAction(recordToDelete);
        }
        setConfirmOpen(false);
    };
    var handleCancel = function () {
        setConfirmOpen(false);
    };
    return (_jsxs(Fragment, { children: [_jsx(_Fragment, { children: _jsxs(DatagridConfigurable, { bulkActionButtons: false, 
                    // rowClick={handleRowClick}
                    // data={data}
                    onChange: handleDataTableChange, children: [_jsx(FunctionField, { source: "hkid", label: translate("pages.subject.fields.hkid"), render: function (record) {
                                return (_jsx(Typography, { children: blueIDHelper(record.hkid) }));
                            } }), _jsx(FunctionField, { source: "id", label: translate("pages.subject.fields.id"), render: function (record) {
                                return (_jsx(Typography, { children: subjectIdHelper(record.id) }));
                            } }), _jsx(TextField, { source: "lastname", label: translate("pages.subject.fields.lastname") }), _jsx(TextField, { source: "firstname", label: translate("pages.subject.fields.firstname") }), _jsx(TextField, { source: "gender", label: translate("pages.subject.fields.gender") }), _jsx(DateField, { source: "dateOfBirth", label: translate("pages.subject.fields.dateOfBirth") }), _jsx(FunctionField, { source: "educationLevel", label: translate("pages.subject.fields.educationLevel"), render: function (record) {
                                var bgColor;
                                switch (record.educationLevel) {
                                    case educationLevelArray[0]:
                                        bgColor = "#aad5fd";
                                        break;
                                    case educationLevelArray[1]:
                                        bgColor = "#6bb0eb";
                                        break;
                                    case educationLevelArray[2]:
                                        bgColor = "#3d7ebe";
                                        break;
                                    case educationLevelArray[3]:
                                        bgColor = "#0d5da8";
                                        break;
                                    case educationLevelArray[4]:
                                        bgColor = "#004587";
                                        break;
                                }
                                return (_jsx(Button, { sx: {
                                        backgroundColor: bgColor,
                                        color: "white",
                                        textTransform: 'none',
                                        transition: 'all 0.3s ease',
                                        '&:hover': {
                                            backgroundColor: 'blue',
                                            transform: 'scale(1.05)',
                                            boxShadow: '0 0 15px rgba(0, 0, 255, 0.5)',
                                            animation: 'pulse 1.5s infinite',
                                        },
                                        '@keyframes pulse': {
                                            '0%': {
                                                boxShadow: '0 0 0 0 rgba(0, 0, 255, 0.4)',
                                            },
                                            '70%': {
                                                boxShadow: '0 0 0 10px rgba(0, 0, 255, 0)',
                                            },
                                            '100%': {
                                                boxShadow: '0 0 0 0 rgba(0, 0, 255, 0)',
                                            },
                                        },
                                    }, children: record.educationLevel }));
                            } }), _jsx(WrapperField, { label: translate("pages.subject.fields.actions"), children: _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", children: [_jsx(FunctionField, { source: "", label: "", render: function (record) {
                                            return (_jsx(Grid, { item: true, onClick: function () {
                                                    // openEditDialog(record)
                                                    setDialogDataAction(record);
                                                    setExamsData(record === null || record === void 0 ? void 0 : record.exams);
                                                    console.log('==logggg', record);
                                                    setOpenLeftPanel(true);
                                                    // navigate('/admin/exams', { state: record })
                                                    localStorage.setItem('hkid_id', record.id);
                                                    localStorage.setItem('hkid', record.hkid);
                                                }, children: _jsx(Tooltip, { title: "view subject", children: _jsx(IconButton, { size: "small", "aria-label": "preview", color: "inherit", children: _jsx(PreviewIcon, { fontSize: "small" }) }) }) }));
                                        } }), acessControlList.subject.edit && _jsx(FunctionField, { source: "", label: "", render: function (record) {
                                            return (_jsx(Grid, { item: true, onClick: function () {
                                                    openEditDialog(record);
                                                }, children: _jsx(Tooltip, { title: "edit subject", children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(EditIcon, { fontSize: "small" }) }) }) }));
                                        } }), acessControlList.subject.delete && _jsx(FunctionField, { source: "", label: "", render: function (record) {
                                            return (_jsx(Grid, { item: true, onClick: function () { handleDelete(record); }, children: _jsx(Tooltip, { title: "delete subject", children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(DeleteIcon, { fontSize: "small" }) }) }) }));
                                        } })] }) })] }) }), _jsx(Confirm, { isOpen: confirmOpen, title: "Delete Subject", content: "Are you sure you want to delete this subject?", onConfirm: handleConfirm, onClose: handleCancel })] }));
};
export default SubjectList;
