import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef } from 'react';
import { Button, Card, CardActions, CircularProgress, Grid, Typography } from '@mui/material';
import { required, TextInput, useTranslate } from 'react-admin';
import Box from '@mui/material/Box';
// import LoginLogo from '../assets/logos/login logo.png'
import ICSLogo from '../../assets/logos/ics logo.png';
var LoginAuth = function (_a) {
    var loading = _a.loading, signInInputs = _a.signInInputs, setCurComp = _a.setCurComp, serverOTP = _a.serverOTP, setServerOTP = _a.setServerOTP, handleTwofaLogin = _a.handleTwofaLogin, enableTwofaResend = _a.enableTwofaResend, setEnableTwofaResend = _a.setEnableTwofaResend;
    var translate = useTranslate();
    // const [countdown, setCountdown] = useState(60);
    var _b = useState(60), timer = _b[0], setTimer = _b[1];
    var increment = useRef(null);
    var resendCountDown = function () {
        increment.current = setInterval(function () {
            setTimer(function (timer) {
                if (timer > 0)
                    return timer - 1;
                else {
                    // clearInterval(increment.current);
                    setEnableTwofaResend(false);
                    setTimer(60);
                    clearInterval(increment.current);
                    return timer;
                }
            });
        }, 1000);
    };
    var handleStartCountDown = function () {
        setEnableTwofaResend(true);
        resendCountDown();
        setTimer(60); //[back] here is the timer for resend
    };
    // const SingleOTPInput = ({ sourceName }) => {
    //     return (<TextInput source={sourceName} label=""
    //         style={{
    //             width: "3rem", margin: "0 0.5rem",
    //             "input::-webkit-outer-spin-button": {
    //                 " -webkit-appearance": "none",
    //                 margin: 0
    //             },
    //             "input::-webkit-inner-spin-button": {
    //                 " -webkit-appearance": "none",
    //                 margin: 0
    //             },
    //             "-webkit-inner-spin-button": {
    //                 " -webkit-appearance": "none",
    //                 margin: 0
    //             },
    //             "-webkit-outer-spin-button": {
    //                 " -webkit-appearance": "none",
    //                 margin: 0
    //             }
    //         }}
    //         defaultValue={otpInputs[sourceName]}
    //         onChange={(e) => {
    //             console.log(e.target.value, '==log cur vAL321')
    //             if (!otpInputs[sourceName]) {
    //                 setOtpInputs({ ...otpInputs, [sourceName]: e.target.value })
    //             }
    //         }}
    //         InputProps={{
    //             // type: 'number',
    //             endAdornment: null,
    //         }}
    //     // inputProps={{ type: 'number' }}
    //     />)
    // }
    return (_jsx(Box, { sx: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'flex-start',
            background: '#ebeff2',
            backgroundSize: 'cover',
        }, children: _jsxs(Card, { sx: { minWidth: 300, marginTop: '6em' }, children: [_jsx(Box, { sx: {
                        margin: '1em',
                        display: 'flex',
                        justifyContent: 'center',
                    }, children: _jsx("img", { src: ICSLogo, alt: "logo", height: 50 }) }), _jsx(Box, { sx: {
                        marginTop: '1em',
                        display: 'flex',
                        flexWrap: "wrap",
                        justifyContent: 'center',
                        color: function (theme) { return theme.palette.grey[500]; },
                    }, children: _jsxs(Grid, { container: true, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", style: { backgroundColor: "", width: "30rem" }, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "h4", textAlign: "center", color: "black", children: "AI Screening Platform" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "h5", textAlign: "center", color: "black", children: "Sign In" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsxs(Typography, { variant: "body1", textAlign: "center", color: "black", children: ["An email with a time-limited 6-digit authentication code will be sent to your inbox.", _jsx("br", {}), " Please enter the code to sign in."] }) })] }) }), _jsxs(Box, { sx: { padding: '0 1em 1em 1em' }, children: [_jsxs(Box, { sx: { marginTop: '1em' }, children: [_jsx(Typography, { variant: "body1", color: "#4d6a95", children: " Email" }), _jsx(TextInput, { autoFocus: true, 
                                    // source="username"
                                    source: "", label: "Your Email", 
                                    // defaultValue={signInInputs?.username}
                                    disabled: true, validate: required(), fullWidth: true, defaultValue: signInInputs === null || signInInputs === void 0 ? void 0 : signInInputs.username }), _jsx(Typography, { children: "6-digit Authentication Code" }), _jsx(TextInput, { autoFocus: true, source: "authenCode", 
                                    // source=""
                                    label: "Authentication Code", disabled: loading, validate: required(), onChange: function (e) {
                                        setServerOTP(e.target.value);
                                    }, fullWidth: true })] }), _jsxs(Grid, { item: true, xs: 12, display: "flex", children: [_jsx(Typography, { variant: "body1", textAlign: "center", color: "black", children: "Didn't receive?" }), !enableTwofaResend && _jsx(Typography, { variant: "body1", textAlign: "center", color: "black", paddingX: 2, style: { cursor: "pointer", color: "blue" }, onClick: function () {
                                        handleTwofaLogin(true);
                                        handleStartCountDown();
                                    }, children: "Resend" }), enableTwofaResend && _jsx(Typography, { variant: "body1", textAlign: "center", color: "grey", paddingLeft: 2, children: "Resend in ".concat(timer, "s") })] })] }), _jsx(CardActions, { sx: { padding: '0 1em 1em 1em' }, children: _jsxs(Button, { variant: "contained", 
                        // type="submit"
                        color: "primary", disabled: loading, fullWidth: true, sx: { backgroundColor: "#4d6a95" }, onClick: function () {
                            handleTwofaLogin();
                        }, children: [loading && (_jsx(CircularProgress, { size: 25, thickness: 2 })), translate('ra.auth.sign_in')] }) }), _jsx(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", padding: 2, children: _jsx(Typography, { variant: "body1", textAlign: "center", color: "#4d6a95", style: { cursor: "pointer" }, onClick: function () { setCurComp("signIn"); }, children: " Back to Sign In" }) })] }) }));
};
export default LoginAuth;
