var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardContent, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import makeStyles from '@mui/styles/makeStyles';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        background: 'transparent',
        boxShadow: 'none',
        border: 'none',
    },
    cardClass: {
        padding: theme.spacing(3),
    },
    cardContent: {
        paddingLeft: 0,
        paddingRight: theme.spacing(0.4),
        paddingTop: 0,
        paddingBottom: '0 !important',
    },
    divider: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    spacer: {
        marginBottom: theme.spacing(3),
    },
    breadcrumbTitle: {
        fontWeight: 500,
        marginTop: theme.spacing(1),
    },
}); });
var Breadcrumbs = function (props) {
    var classes = useStyles();
    var color = props.color, outline = props.outline, size = props.size, title = props.title, divider = props.divider, isCard = props.isCard, rest = __rest(props, ["color", "outline", "size", "title", "divider", "isCard"]);
    var cardClass = classes.root;
    if (isCard) {
        cardClass = classes.cardClass;
    }
    return (_jsx(Card, { className: cardClass, children: _jsxs(CardContent, { className: classes.cardContent, children: [_jsx(MuiBreadcrumbs, __assign({}, rest)), title && (_jsx(Typography, { className: classes.breadcrumbTitle, variant: "h3", children: title }))] }) }));
};
export default Breadcrumbs;
