import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { DatagridConfigurable, TextField, FunctionField, } from 'react-admin';
import { Typography, Grid, FormControlLabel, Checkbox, } from '@mui/material';
var ExportListHeader = function (_a) {
    var data = _a.data, handleBulkSelection = _a.handleBulkSelection;
    return (_jsx(Fragment, { children: _jsxs(DatagridConfigurable, { header: _jsx("div", {}), data: data, sx: {
                backgroundColor: "",
                //  pointerEvents: "none"
            }, children: [_jsx(FunctionField, { source: "id", label: "", render: function (record) {
                        return (_jsx(Grid, { item: true, md: 3, xs: 4, display: "flex", flexWrap: "wrap", justifyContent: "flex-end", style: { backgroundColor: "" }, children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: record.selected, onChange: function (e) { handleBulkSelection(record, record.selected, e.target.checked); } }) }) }));
                    } }), _jsx(FunctionField, { source: "hkid", label: data.hkid, render: function (record) {
                        return (_jsx(Typography, { paddingLeft: 7, paddingRight: 7, children: record.hkid }));
                    } }), _jsx(FunctionField, { source: "id", label: data.id, render: function (record) {
                        return (_jsx(Typography, { children: record.id }));
                    } }), _jsx(TextField, { source: "lastname", label: data.lastname }), _jsx(TextField, { source: "firstname", label: data.firstname }), _jsx(TextField, { source: "gender", label: data.gender }), _jsx(TextField, { source: "dateOfBirth", label: data.dateOfBirth }), _jsx(FunctionField, { source: "educationLevel", label: data.educationLevel, render: function (record) {
                        return (_jsx(Typography, { children: record.educationLevel }));
                    } })] }) }));
};
export default ExportListHeader;
