var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import SignIn from './LoginPages/SignIn';
import ForgetPW from './LoginPages/ForgetPW';
import LoginAuth from './LoginPages/LoginAuth';
import packageJson from '../../package.json';
import { forgetPW, loginWithTwofaChecking, loginSuccess, twofaTokenChecking } from '../providers/authProvider';
import { Typography, } from '@mui/material';
import { Form, useTranslate, useLogin, useNotify, useDataProvider, useRefresh, } from 'react-admin';
var returnOTPDigit = function (value) {
    if (!isNaN(parseInt(value))) {
        return parseInt(value);
    }
    else {
        return '';
    }
};
var Login = function () {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState("signIn"), curComp = _b[0], setCurComp = _b[1]; //component for -- page 1: signIn, loginAuth,forgetPW || page 2: register
    var _c = useState({}), authData = _c[0], setAuthData = _c[1]; //save auth info from server
    var _d = useState(false), enableTwofaResend = _d[0], setEnableTwofaResend = _d[1]; //check if can reset 2fa - from countdown
    var _e = useState(), serverOTP = _e[0], setServerOTP = _e[1]; //otp for user input
    var _f = useState(false), needTwofa = _f[0], setNeedTwofa = _f[1]; //check if need 2fa
    //sign in info
    var _g = useState({ username: "", password: "" }), signInInputs = _g[0], setSignInInputs = _g[1];
    var translate = useTranslate();
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var login = useLogin();
    var refresh = useRefresh();
    var location = useLocation();
    var navigate = useNavigate();
    // document.body.style.zoom = "100%";
    useEffect(function () {
    }, []);
    // for sign in
    var handleSubmit = function (auth) { return __awaiter(void 0, void 0, void 0, function () {
        var loginData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    return [4 /*yield*/, loginWithTwofaChecking(
                        // auth,
                        // location.state ? (location.state as any).nextPathname : '/'
                        {
                            username: signInInputs.username, password: signInInputs.password,
                        })
                            .catch(function (error) {
                            setLoading(false);
                            notify(typeof error === 'string'
                                ? error
                                : typeof error === 'undefined' || !error.message
                                    ? 'ra.auth.sign_in_error'
                                    : error.message, {
                                type: 'error',
                                messageArgs: {
                                    _: typeof error === 'string'
                                        ? error
                                        : error && error.message
                                            ? error.message
                                            : undefined,
                                },
                            });
                        })];
                case 1:
                    loginData = _a.sent();
                    // if is twofa, set auth page
                    if ((loginData === null || loginData === void 0 ? void 0 : loginData.message) && (loginData === null || loginData === void 0 ? void 0 : loginData.message) === 'Need 2FA') {
                        setNeedTwofa(true);
                        setAuthData(loginData);
                        // setServerOTP(loginData.twofa);
                        setCurComp("loginAuth");
                    }
                    else {
                        loginSuccess(loginData);
                        refresh();
                        navigate('/admin/subjects');
                        // setLoading(true);
                        // setTimeout(() => {
                        //     navigate('/admin/subjects');
                        //     setLoading(false);
                        // }, 3000);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    // for forget password
    var handleForgetPW = function (auth) {
        setLoading(true);
        var params = { email: signInInputs.username };
        forgetPW(params)
            .then(function (data) {
            refresh();
            notify('Email sent');
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
            notify(typeof error === 'string'
                ? error
                : typeof error === 'undefined' || !error.message
                    ? 'ra.auth.sign_in_error'
                    : error.message, {
                type: 'error',
                messageArgs: {
                    _: typeof error === 'string'
                        ? error
                        : error && error.message
                            ? error.message
                            : undefined,
                },
            });
        });
    };
    // for twofa checking for login
    var handleTwofaLogin = function (isResend) { return __awaiter(void 0, void 0, void 0, function () {
        var params, rtnData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    if (!isResend) return [3 /*break*/, 2];
                    notify("6-digit code sent");
                    return [4 /*yield*/, handleSubmit()];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2:
                    params = { "username": authData.username, "token": serverOTP };
                    return [4 /*yield*/, twofaTokenChecking(params)];
                case 3:
                    rtnData = _a.sent();
                    if ((rtnData === null || rtnData === void 0 ? void 0 : rtnData.message) === "Invalid token") {
                        notify(rtnData === null || rtnData === void 0 ? void 0 : rtnData.message);
                    }
                    else {
                        loginSuccess(loginData);
                        refresh();
                        navigate('/admin/subjects');
                        // setLoading(true);
                        // setTimeout(() => {
                        //     navigate('/admin/subjects');
                        //     setLoading(false);
                        // }, 3000);
                    }
                    setLoading(false);
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Form, { onSubmit: handleSubmit, noValidate: true, children: [curComp === 'signIn' && _jsx(SignIn, { loading: loading, signInInputs: signInInputs, setSignInInputs: setSignInInputs, setCurComp: setCurComp }), curComp === 'forgetPW' && _jsx(ForgetPW, { notify: notify, loading: loading, signInInputs: signInInputs, setSignInInputs: setSignInInputs, setCurComp: setCurComp, handleForgetPW: handleForgetPW }), curComp === 'loginAuth' && _jsx(LoginAuth, { loading: loading, signInInputs: signInInputs, setCurComp: setCurComp, serverOTP: serverOTP, setServerOTP: setServerOTP, handleTwofaLogin: handleTwofaLogin, enableTwofaResend: enableTwofaResend, setEnableTwofaResend: setEnableTwofaResend }), _jsxs(Typography, { variant: "body2", color: "textSecondary", align: "center", style: { marginTop: '0.5em', fontWeight: 'bold' }, children: ["Version: ", packageJson.version] })] }));
};
Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};
export default Login;
