// // store.js
// import { combineReducers } from 'redux';
import { subjectListReducer } from './subjectListReducer';
// const reducer = combineReducers({
//     subjectListData: subjectListReducer,
// });
// export default reducer;
import { configureStore } from '@reduxjs/toolkit';
export default configureStore({
    reducer: {
        subjectListData: subjectListReducer
    },
});
