var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Admin, CustomRoutes, Resource, localStorageStore, StoreContextProvider, } from 'react-admin';
// import { Route } from 'react-router';
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import authProvider from './demoServerAndData/authProvider';
// import dataProviderFactory from './demoServerAndData/dataProvider';
import { authProvider } from './providers/authProvider';
import { dataProvider } from './providers/dataProvider';
import englishMessages from './i18n/en';
import { Layout, Login } from './layout';
import Dashboard from './dashboardPage/Dashboard';
import DataExport from './dataExportPage/DataExport';
import Settings from './settingsPage/Settings';
import RetinADReport1 from './examsPage/fp/RetinADReport1';
import "./App.css";
import { Provider } from 'react-redux';
// import { configureStore } from '@reduxjs/toolkit';
import configureStore from './utils/store/store';
// import reducer from './utils/store/store';
import subject from './subjectPage';
import exam from './examsPage';
import userList from './userPage';
import organization from './OrganizationPage';
var i18nProvider = polyglotI18nProvider(function (locale) {
    if (locale === 'cn') {
        return import('./i18n/cn').then(function (messages) { return messages.default; });
    }
    // Always fallback on english
    return englishMessages;
}, 'en', [
    { locale: 'en', name: 'English' },
    // { locale: 'cn', name: '中文' },
]);
var store = localStorageStore(undefined, 'AISP');
var AdminPortal = function () { return (_jsxs(Admin, { title: "", 
    // dataProvider={dataProviderFactory(
    //     process.env.REACT_APP_DATA_PROVIDER || ''
    // )}
    basename: '/admin', store: store, requireAuth: true, authProvider: authProvider, dataProvider: dataProvider, loginPage: Login, layout: Layout, i18nProvider: i18nProvider, disableTelemetry: true, defaultTheme: "light", children: [_jsxs(CustomRoutes, { children: [_jsx(Route, { path: "/dashboard", element: _jsx(Dashboard, {}) }), _jsx(Route, { path: "/unauthorized", element: _jsx("div", { children: "404 not found" }) })] }), _jsxs(CustomRoutes, { children: [_jsx(Route, { path: "/settings", element: _jsx(Settings, {}) }), _jsx(Route, { path: '/fp/report', element: _jsx(RetinADReport1, {}) }), _jsx(Route, { path: "/dataexport", element: _jsx(DataExport, {}) })] }), _jsx(Resource, __assign({ name: "exams" }, exam, { options: { label: '' } })), _jsx(Resource, __assign({ name: "subjects" }, subject, { options: { label: '' } })), _jsx(Resource, __assign({ name: "users" }, userList, { options: { label: ' ' } })), _jsx(Resource, __assign({ name: "organizations" }, organization, { options: { label: ' ' } }))] })); };
var App = function () {
    return (_jsx(Provider, { store: configureStore, children: _jsx("div", { style: { maxHeight: "95vh", }, children: _jsx(BrowserRouter, { children: _jsx(Routes, { children: _jsx(Route, { path: "/admin/*", element: _jsx(AdminPortal, {}) }) }) }) }) }));
};
var AppWrapper = function () { return (_jsx(StoreContextProvider, { value: store, children: _jsx(App, {}) })); };
export default AppWrapper;
