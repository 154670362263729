var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import englishMessages from 'ra-language-english';
var customEnglishMessages = __assign(__assign({}, englishMessages), { login: {
        signIn: "Sign in",
        resend: "Resend",
        forgetPW: {
            caption: {
                willSendEmail: "An email will be sent to your inbox to initiate the password reset process.",
                sentEmail: "An email has been sent to your inbox. Please check your email to continue the password reset process.",
                notReceiveEmail: "Not receiving the email?",
            }
        }
    }, pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        menu: {
            files: 'Files',
            main: 'Main',
            userManagement: 'User Management',
        }
    }, common: {
        search: "Search",
        save: "Save",
        analyze: "Analyze",
        close: "Close",
        confirm: "Confirm",
        back: "Back",
    }, dialog: {
        export: "Export",
        submit: "Submit",
        submit_and_import: "Submit & Import Data",
        Edit: "Edit",
        Create: "Create"
    }, pages: {
        common: {
            selection: {
                select_all: "Select all",
                reset: "Reset",
            },
        },
        subject: {
            name: 'Subject',
            title: 'Subject',
            fields: {
                hkid: "HK ID",
                id: "Subject ID",
                firstname: "First name",
                lastname: "Last name",
                gender: "Gender",
                dateOfBirth: "Date of birth",
                educationLevel: "Education level",
                actions: "Actions",
                examFromDate: "Exam - From",
                examToDate: "Exam - To",
                analyzeFromDate: "Analyze - From",
                analyzeToDate: "Analyze - To",
            },
            addNew: "Add new subject"
        },
        exam: {
            name: 'Exam',
            title: 'Exam',
            fields: {
                exam_date: "Exam date",
                ai_model: "AI model",
                eye: "Eye",
                risk_of_ad: "Risk of AD",
                confidence_level: "Confidence lv.",
                status: "Status",
                analysisDate: "Analysis Date",
                actions: "Actions",
                hkid: "HK ID",
                subject_id: "Subject ID",
                id: "Exam ID",
                firstname: "First name",
                lastname: "Last name",
                gender: "Gender",
                dateOfBirth: "Date of birth",
                educationLevel: "Education level",
                examFromDate: "Exam - From",
                examToDate: "Exam - To",
                analyzeFromDate: "Analyze - From",
                analyzeToDate: "Analyze - To",
            },
            dialog: {
                edit_exam: 'Edit Exam ',
                add_new_exam: 'Add New Exam Date',
            },
            fp: {
                analyzeCases: {
                    cases: "Analyzable cases:",
                    both: "1. Both eyes: Macula-centered and not Macula-centered left & right eye images ",
                    leftOnly: "2. Left eyes only:  Macula-centered and not Macula-centered left eye images",
                    rightOnly: "3. Right eyes only:  Macula-centered and not Macula-centered right eye images",
                }
            },
        },
        dataExport: {
            name: 'Data Export',
            criteria: 'Data Export Criteria',
            fields: {
                subject_information: "Subject Information",
                images: "Images",
                fps: "FPs",
                fp: "FP",
                oct_reports: "Oct reports",
                retinAD_reports: "RetinAD reports",
                oct: "OCT",
                fp_oct: "FP + OCT",
                retinAD_probability_scores: "RetinAD probability scores",
                internal_use: "Internal use only",
                questionnaire_results: "Questionnaire results",
                examDate: "Exam",
                analyzeDate: "Analyze",
                dateFrom: "From",
                dateTo: "To",
            },
            dialog: {
                subjects_selected: "No. of subject(s) selected: "
            }
        },
        settings: {
            name: 'Settings',
            leftCol: {
                profile: "Profile",
                language: "Language",
                security: "Security",
                membership: "Membership",
                payment: "Payment",
            },
            fields: {
                profile: {
                    headers: {
                        info: "General Information",
                        set_new_pw: "Set New Password",
                        access: "View Your Access Right"
                    },
                    info: {
                        user_id: "User ID",
                        user_group: "User group",
                        username: "Username",
                        firstname: "First name",
                        lastname: "Last name",
                        gender: "Gender",
                        email: "Email",
                    },
                    password: {
                        current: "Current PW",
                        new: "New Password",
                        confirm_new: "Confirm New PW",
                    },
                    access: {
                        dashboard: "Dashboard",
                        user_group: "User Group",
                        subject: "Subject",
                        data_export: "Data Export",
                    },
                    crud: {
                        c: "Add (C)",
                        r: "View (R)",
                        u: "Edit (U)",
                        d: "Delete (D)",
                    }
                },
                language: {
                    lan_caption: "Change language display setting"
                },
                security: {
                    header2fa: "Two Factor Authentication",
                    caption2fa: "Adds an extra security step to login, requiring a time-limited 6-digit code sent to your device to verify it's you."
                },
            },
        },
        membership: {
            btn: {
                cancel: "Cancel",
                renew: "Renew",
                subsMonth: "Subscribe (Month)",
                subsYear: "Subscribe (Year)",
            },
            fields: {
                plans: {
                    genLeft: " generations left",
                    currentPlan: "Current Plan",
                    advanced: "Advanced",
                    monthly: "Monthly",
                    yearly: "Yearly",
                    caption: "* Unless you choose to cancel your subscription, your membership will automatically renew upon expiration.",
                },
                headers: {
                    current: "Current Plan",
                    basic: "Basic",
                    standard: "Standard",
                    advanced: "Advanced",
                    premium: "Premium"
                },
                details: {
                    subsPreTermsAndCond: "By subscribing, you agree to our ",
                    termsAndCond: " Terms & Conditions.",
                    unlimited: "Unlimited",
                    genMonthly: "AI report generations per month",
                    month: "Month",
                    year: "Year",
                    save2monthfee: "saving 2 months of fees",
                },
            },
        },
        userList: {
            name: 'User',
            listName: "User List",
            fields: {
                id: "User ID",
                userGroup: "User group",
                username: "Username",
                organization: "Organization",
                email: "Email",
                status: "Status",
                createdAt: "Created at",
                updatedAt: "Updated at",
                pw: "Password",
                confirmpw: "Confirm PW",
            },
            addNew: "Add new user",
            info: "User Information",
        },
        organizations: {
            name: 'Organizations',
            editComp: {
                general: "General",
                groups: "Groups",
                users: "Users",
            },
            fields: {
                orgId: "Org ID",
                id: "Group ID",
                userGroup: "User group",
                username: "Username",
                organization: "Organization",
                orgName: "Org name",
                groupNum: "Number of Groups",
                userNum: "Number of Users",
                email: "Email",
                status: "Status",
                createdAt: "Created at",
                updatedAt: "Updated at",
            },
            addNew: "Add new organization",
            info: "User Information",
        },
        dashboard: {
            name: 'Dashboard',
            amount: '1 review |||| %{smart_count} reviews',
            relative_to_poster: 'Review on poster',
            detail: 'Review detail',
            fields: {
                customer_id: 'Customer',
                command_id: 'Order',
                product_id: 'Product',
                date_gte: 'Posted since',
                date_lte: 'Posted before',
                date: 'Date',
                comment: 'Comment',
                rating: 'Rating',
            },
            action: {
                accept: 'Accept',
                reject: 'Reject',
            },
            notification: {
                approved_success: 'Review approved',
                approved_error: 'Error: Review not approved',
                rejected_success: 'Review rejected',
                rejected_error: 'Error: Review not rejected',
            },
        },
    } });
export default customEnglishMessages;
