import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { List, MenuItem, Typography, Collapse, Tooltip, } from '@mui/material';
import { useTranslate, useSidebarState } from 'react-admin';
var SubMenu = function (props) {
    var handleToggle = props.handleToggle, isOpen = props.isOpen, name = props.name, icon = props.icon, children = props.children, dense = props.dense;
    var translate = useTranslate();
    var sidebarIsOpen = useSidebarState()[0];
    var header = (_jsxs(MenuItem, { dense: dense, onClick: handleToggle, children: [icon, _jsx(Typography, { variant: "inherit", color: "textSecondary", fontWeight: "900", sx: { marginLeft: '8px', fontSize: '0.8rem' }, children: translate(name) })] }));
    return (_jsxs("div", { children: [sidebarIsOpen || isOpen ? (header) : (_jsx(Tooltip, { title: translate(name), placement: "right", children: header })), _jsx(Collapse, { in: isOpen, timeout: "auto", unmountOnExit: true, children: _jsx(List, { dense: dense, component: "div", disablePadding: true, sx: {
                        '& .MuiMenuItem-root': {
                            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                            paddingLeft: function (theme) {
                                return sidebarIsOpen
                                    ? theme.spacing(4)
                                    : theme.spacing(2);
                            },
                        },
                    }, children: children }) })] }));
};
export default SubMenu;
