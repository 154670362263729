var rightPanelstyles = {
    dataExportContainer: {
        backgroundColor: "white",
        width: "100%",
        height: "100%",
        margin: "1rem",
        padding: "0.5rem",
        borderRadius: "15px",
        boxShadow: "5px 5px lightgrey",
        '-moz-box-shadow': '0 0 3px lightgrey',
        '-webkit-box-shadow': '0 0 3px lightgrey',
        'box-shadow': '0 0 3px lightgrey',
    },
};
var userListstyles = {
    userList: {
        // backgroundColor: "red",
        // paddingY: "200px",
        width: "100%",
        overflowY: "scroll"
    },
};
export { rightPanelstyles, userListstyles };
