//  MUI
var EyeMuiChoices = [
    { value: 'Left', label: 'Left' },
    { value: 'Right', label: 'Right' },
    { value: 'Both', label: 'Both' }
];
var AIModelMuiChoices = [
    { value: 'FP + OCT', label: 'FP + OCT' },
    { value: 'FP', label: 'FP' },
    { value: 'OCT', label: 'OCT' }
];
export { EyeMuiChoices, AIModelMuiChoices };
