var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DatagridConfigurable, DateField, SelectColumnsButton, TextField, TopToolbar, FunctionField, useDataProvider, useNotify, useRefresh, useTranslate, } from 'react-admin';
import { GenderMuiChoices, EducationLevelMuiChoices } from '../subjectPage/Params';
import { useMediaQuery, Divider, Typography, Grid, Button, FormControlLabel, Checkbox, } from '@mui/material';
import { blueIDHelper } from '../utils/helper';
// import MobileGrid from './MobileGrid';
import { general } from '../utils/componentWizard';
import FilterList from './FilterList';
import { useDispatch } from 'react-redux';
import { subjectIdHelper } from '../utils/helper';
import ExportListHeader from './helper/ExportListHeader';
import { Box } from '@mui/material';
var ListActions = function () { return (_jsx(TopToolbar, { children: _jsx(SelectColumnsButton, {}) })); };
var ExportList = function (_a) {
    var props = _a.props, data = _a.data, setData = _a.setData, isLoading = _a.isLoading, setIsLoading = _a.setIsLoading, filters = _a.filters, setFilters = _a.setFilters, previewExport = _a.previewExport, dataGridHeader = _a.dataGridHeader, setDataGridHeader = _a.setDataGridHeader;
    var dataProvider = useDataProvider();
    // const resource = useResourceContext(props)
    var resource = props;
    var notify = useNotify();
    var refresh = useRefresh();
    var navigate = useNavigate();
    // const listContext = useListContext();
    var translate = useTranslate();
    var _b = useState(false), hasBulkSelect = _b[0], setHasBulkSelect = _b[1]; //check if all data is selected from bulk selection
    var _c = useState({ gender: GenderMuiChoices, educationLevel: EducationLevelMuiChoices }), params = _c[0], setParams = _c[1];
    var educationLevelArray = ["0-3 yrs", "4-6 yrs", "7-9 yrs", "10-12 yrs", "> 12 yrs"];
    var _d = useState(false), selectedColumn = _d[0], setSelectedColumn = _d[1];
    var _e = useState(), dialogData = _e[0], setDialogData = _e[1];
    // for left panel
    var _f = useState(), selectedRow = _f[0], setSelectedRow = _f[1];
    // const { sort } = useListController();
    // console.log('==listController', sort)
    // document.body.style.zoom = "100%";
    useEffect(function () {
        var _a;
        var sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
        if (sessonStorageJSON) {
            setFilters(__assign(__assign({}, filters), sessonStorageJSON));
        }
        sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(__assign(__assign({}, filters), sessonStorageJSON)));
        searchFilterGroup();
    }, []);
    useEffect(function () {
        console.log(' ');
    }, [selectedRow]);
    // filter group when "search"
    var searchFilterGroup = function () {
        var _a;
        setIsLoading(true);
        var sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
        var params = {
            pagination: { page: 1, perPage: 25 },
            sort: { field: "id", order: "ASC" },
            // filter: updatedFilters //[back][0715] resume this
            filter: __assign(__assign({}, filters), sessonStorageJSON)
        };
        console.log('==log params on subject list', params, props);
        dataProvider.getList(props, params)
            .then(function (data) {
            var updatedData = data.data;
            // set "selected" for bulk action
            updatedData.map(function (el, idx) { el.selected = false; });
            console.log('==log data', updatedData);
            // let headerData = {
            //     "id": translate(`pages.subject.fields.id`),
            //     "hkid": translate(`pages.subject.fields.hkid`),
            //     "firstname": translate(`pages.subject.fields.firstname`),
            //     "lastname": translate(`pages.subject.fields.lastname`),
            //     "gender": translate(`pages.subject.fields.gender`),
            //     "educationLevel": translate(`pages.subject.fields.educationLevel`),
            //     "dateOfBirth": translate(`pages.subject.fields.dateOfBirth`),
            //     "isHeader": true,
            //     "selected": false
            // }
            // // setData([...headerData, ...updatedData]);
            // updatedData.unshift(headerData);
            setData(updatedData);
            refresh();
            notify('Subject fetched');
            setIsLoading(false);
        }).catch(function (error) {
            // Handle any errors that occurred
            console.error(error);
            setIsLoading(false);
        });
    };
    // handle selection 
    var handleBulkSelection = function (record, selected, checked) {
        var updatedData = JSON.parse(JSON.stringify(data));
        var selectedIndex = updatedData.findIndex(function (item) { return item.id === record.id; });
        console.log('==isHeader', checked, record, record.isHeader);
        if (record.isHeader) {
            switch (checked) {
                case true:
                    updatedData.map(function (el, idx) { el.selected = true; });
                    setHasBulkSelect(true);
                    break;
                default:
                    updatedData.map(function (el, idx) { el.selected = false; });
                    setHasBulkSelect(false);
                    break;
            }
            var updatedDataGridHeader = __spreadArray([], dataGridHeader, true);
            updatedDataGridHeader[0].selected = checked;
            setDataGridHeader(updatedDataGridHeader);
        }
        else {
            switch (selected) {
                case true:
                    updatedData[selectedIndex]['selected'] = false;
                    var currentHasBulkSelect_1 = false;
                    updatedData.map(function (el, idx) {
                        if (el.selected) {
                            currentHasBulkSelect_1 = true;
                        }
                    });
                    setHasBulkSelect(currentHasBulkSelect_1);
                    break;
                default:
                    updatedData[selectedIndex]['selected'] = true;
                    setHasBulkSelect(true);
                    break;
            }
        }
        setData(updatedData);
    };
    return (_jsx(Grid, { container: true, display: "flex", children: _jsxs(Grid, { item: true, sx: { marginTop: "1em" }, children: [_jsx(general.breadcrumb, { trail: ["Main", "".concat(translate("pages.dataExport.name"))] }), _jsx(Grid, { item: true, display: "flex", alignItems: "center", justifyContent: "start", children: _jsx(Typography, { variant: "h5", sx: { fontWeight: "900", marginBottom: "0.1em" }, children: translate("pages.dataExport.name") }) }), _jsx(Divider, {}), _jsx(Box, { sx: { position: 'sticky', top: 0, marginY: "1rem", zIndex: 1000, backgroundColor: 'white' }, children: _jsx(FilterList, { translate: translate, search: searchFilterGroup, isLoading: isLoading, params: params, filters: filters, setFilters: setFilters }) }), _jsxs(Grid, { item: true, paddingX: 3, children: [_jsx(ExportListHeader, { data: dataGridHeader, handleBulkSelection: handleBulkSelection }), _jsx(TableDatagrid, { translate: translate, isLoading: isLoading, data: data, setData: setData, educationLevelArray: educationLevelArray, handleBulkSelection: handleBulkSelection, previewExport: previewExport, dataProvider: dataProvider, refresh: refresh, notify: notify, resource: resource, navigate: navigate })] }), hasBulkSelect &&
                    _jsx(Grid, { item: true, margin: 2, display: "flex", alignItems: "center", justifyContent: "end", children: _jsx(Button, { variant: "contained", onClick: function () {
                                previewExport();
                            }, sx: {
                                background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                                border: 0,
                                borderRadius: 3,
                                boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                                color: 'white',
                                height: 60,
                                padding: '0 30px',
                                transition: 'all 0.3s ease-in-out',
                                position: 'relative',
                                overflow: 'hidden',
                                marginRight: "1em",
                                '&:hover': {
                                    background: 'linear-gradient(45deg, #21CBF3 30%, #2196F3 90%)',
                                    transform: 'translateY(-3px) scale(1.05)',
                                    boxShadow: '0 6px 20px rgba(33, 203, 243, .5)',
                                },
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    top: '-50%',
                                    left: '-50%',
                                    width: '200%',
                                    height: '200%',
                                    background: 'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0) 100%)',
                                    transform: 'rotate(30deg)',
                                    animation: 'shine 3s infinite linear',
                                },
                                '@keyframes shine': {
                                    '0%': { transform: 'translateX(-100%) rotate(30deg)' },
                                    '100%': { transform: 'translateX(100%) rotate(30deg)' },
                                },
                            }, children: "Export" }) })] }) }));
};
var TableDatagrid = function (_a) {
    var translate = _a.translate, data = _a.data, setData = _a.setData, isLoading = _a.isLoading, educationLevelArray = _a.educationLevelArray, dataProvider = _a.dataProvider, refresh = _a.refresh, notify = _a.notify, resource = _a.resource, navigate = _a.navigate, handleBulkSelection = _a.handleBulkSelection, previewExport = _a.previewExport;
    // const { filterValues, setFilters, displayedFilters, sort } = listContext;
    // console.log(sort, '==listContext')
    var isXSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm');
    });
    var dispatch = useDispatch();
    // const controllerProps = useListController();
    var columns = [
        {
            source: 'id',
            label: '',
            render: function (record) { return (_jsx(Grid, { item: true, md: 3, xs: 4, display: "flex", flexWrap: "wrap", justifyContent: "flex-end", children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: record.selected, onChange: function (e) { return handleBulkSelection(record, record.selected, e.target.checked); } }) }) })); }
        },
        {
            source: 'hkid',
            label: translate("pages.subject.fields.hkid"),
            render: function (record) { return (_jsx(Typography, { children: !record.isHeader ? blueIDHelper(record.hkid) : record.hkid })); }
        },
        {
            source: 'id',
            label: translate("pages.subject.fields.id"),
            render: function (record) { return (_jsx(Typography, { children: subjectIdHelper(record.id) })); }
        },
        {
            source: 'lastname',
            label: translate("pages.subject.fields.lastname")
        },
        {
            source: 'firstname',
            label: translate("pages.subject.fields.firstname")
        },
        {
            source: 'gender',
            label: translate("pages.subject.fields.gender")
        },
        {
            source: 'dateOfBirth',
            label: translate("pages.subject.fields.dateOfBirth")
        },
        {
            source: 'educationLevel',
            label: translate("pages.subject.fields.educationLevel"),
            render: function (record) {
                var bgColor;
                switch (record.educationLevel) {
                    case educationLevelArray[0]:
                        bgColor = "#aad5fd";
                        break;
                    case educationLevelArray[1]:
                        bgColor = "#6bb0eb";
                        break;
                    case educationLevelArray[2]:
                        bgColor = "#3d7ebe";
                        break;
                    case educationLevelArray[3]:
                        bgColor = "#0d5da8";
                        break;
                    case educationLevelArray[4]:
                        bgColor = "#004587";
                        break;
                }
                return (_jsx(Button, { style: {
                        backgroundColor: bgColor,
                        color: "white",
                        ':hover': { background: 'none' },
                        textTransform: 'none'
                    }, children: record.educationLevel }));
            }
        }
    ];
    return (_jsx(Fragment, { children: _jsxs(DatagridConfigurable, { header: _jsx("div", {}), 
            // columns={columns}
            // children={columns}
            isLoading: isLoading, 
            // rowClick={handleRowClick}
            data: data, children: [_jsx(FunctionField, { source: "id", label: "", render: function (record) {
                        return (_jsx(Grid, { item: true, md: 3, xs: 4, display: "flex", flexWrap: "wrap", justifyContent: "flex-end", children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: record.selected, onChange: function (e) { handleBulkSelection(record, record.selected, e.target.checked); } }) }) }));
                    } }), _jsx(FunctionField, { source: "hkid", label: translate("pages.subject.fields.hkid"), render: function (record) {
                        return (_jsx(Typography, { children: !record.isHeader ? blueIDHelper(record.hkid) : record.hkid }));
                    } }), _jsx(FunctionField, { source: "id", label: translate("pages.subject.fields.id"), render: function (record) {
                        return (_jsx(Typography, { children: subjectIdHelper(record.id) }));
                    } }), _jsx(TextField, { source: "lastname", label: translate("pages.subject.fields.lastname") }), _jsx(TextField, { source: "firstname", label: translate("pages.subject.fields.firstname") }), _jsx(TextField, { source: "gender", label: translate("pages.subject.fields.gender") }), _jsx(DateField, { source: "dateOfBirth", label: translate("pages.subject.fields.dateOfBirth") }), _jsx(FunctionField, { source: "educationLevel", label: translate("pages.subject.fields.educationLevel"), render: function (record) {
                        var bgColor;
                        switch (record.educationLevel) {
                            case educationLevelArray[0]:
                                bgColor = "#aad5fd";
                                break;
                            case educationLevelArray[1]:
                                bgColor = "#6bb0eb";
                                break;
                            case educationLevelArray[2]:
                                bgColor = "#3d7ebe";
                                break;
                            case educationLevelArray[3]:
                                bgColor = "#0d5da8";
                                break;
                            case educationLevelArray[4]:
                                bgColor = "#004587";
                                break;
                        }
                        return (_jsx(Button, { sx: {
                                backgroundColor: bgColor,
                                color: "white",
                                textTransform: 'none',
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                    backgroundColor: 'blue',
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 0 15px rgba(0, 0, 255, 0.5)',
                                    animation: 'pulse 1.5s infinite',
                                },
                                '@keyframes pulse': {
                                    '0%': {
                                        boxShadow: '0 0 0 0 rgba(0, 0, 255, 0.4)',
                                    },
                                    '70%': {
                                        boxShadow: '0 0 0 10px rgba(0, 0, 255, 0)',
                                    },
                                    '100%': {
                                        boxShadow: '0 0 0 0 rgba(0, 0, 255, 0)',
                                    },
                                },
                            }, children: record.educationLevel }));
                    } })] }) }));
};
export default ExportList;
