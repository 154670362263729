var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDataProvider, useNotify, useRefresh, useTranslate } from 'react-admin';
import { Typography, Grid } from '@mui/material';
import { orgStyles as styles } from './helper/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
import SecurityIcon from '@mui/icons-material/Security';
import GeneralComp from './editDialogComps/General';
import GroupComp from './editDialogComps/Group';
import UserComp from './editDialogComps/User';
// individual panel component
var PanelComp = function (_a) {
    var icon = _a.icon, text = _a.text, handleOnclick = _a.handleOnclick, backgroundColor = _a.backgroundColor, name = _a.name;
    return (_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", padding: 0.5, style: { cursor: "pointer", backgroundColor: backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : "white" }, onClick: function () { handleOnclick(name); }, children: [_jsx(Typography, { variant: "body", sx: { paddingRight: "0.5rem" }, children: icon }), _jsx(Typography, { variant: "body", children: text })] }));
};
var EditComp = function (_a) {
    var data = _a.data, closeDialog = _a.closeDialog, opened = _a.opened, reloadRecord = _a.reloadRecord, setDialogData = _a.setDialogData, resource = _a.resource;
    var location = useLocation();
    var navigate = useNavigate();
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    var translate = useTranslate();
    var _b = useState({}), params = _b[0], setParams = _b[1];
    var _c = useState([]), groupData = _c[0], setGroupData = _c[1];
    var _d = useState([]), userData = _d[0], setUserData = _d[1];
    // const resource = "dataexport";
    var panelCompNames = [{ name: "general", label: translate("pages.organizations.editComp.general"), icon: _jsx(AccountCircleIcon, {}) },
        { name: "groups", label: translate("pages.organizations.editComp.groups"), icon: _jsx(LanguageIcon, {}) },
        { name: "users", label: translate("pages.organizations.editComp.users"), icon: _jsx(SecurityIcon, {}) },
    ];
    var _e = useState(true), isLoading = _e[0], setIsLoading = _e[1];
    var _f = useState("general"), curSelectedComp = _f[0], setCurSelectedComp = _f[1]; //profile, language, security
    var _g = useState({}), originalInfo = _g[0], setOriginalInfo = _g[1];
    var _h = useState({}), filters = _h[0], setFilters = _h[1];
    useEffect(function () { return __awaiter(void 0, void 0, void 0, function () {
        var params, newGroupData, newUserData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = {};
                    return [4 /*yield*/, dataProvider.getOneExtend(resource, data.id, "groups")];
                case 1:
                    newGroupData = (_a.sent()).data;
                    setGroupData(newGroupData);
                    return [4 /*yield*/, dataProvider.getOneExtend(resource, data.id, "users")];
                case 2:
                    newUserData = (_a.sent()).data;
                    setUserData(newUserData);
                    return [2 /*return*/];
            }
        });
    }); }, []);
    return (_jsxs(Grid, { container: true, display: "flex", flexWrap: "wrap", style: styles.settingsContainer, children: [_jsx(Grid, { item: true, lg: 1.5, md: 2, xs: 12, style: styles.componentContainer, children: panelCompNames.map(function (el, idx) {
                    var backgroundColor;
                    if (el.name === curSelectedComp) {
                        backgroundColor = "#F2F2F2";
                    }
                    return (_jsx(PanelComp, { icon: el.icon, text: el.label, name: el.name, backgroundColor: backgroundColor, handleOnclick: setCurSelectedComp }));
                }) }), _jsxs(Grid, { item: true, lg: 10, md: 9, xs: 12, style: styles.componentContainer, children: [_jsxs(Grid, { item: true, display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 2, children: [_jsx(Grid, { item: true, children: _jsx(Typography, { variant: "h4", sx: { paddingRight: '10px' }, children: translate("pages.organizations.editComp.".concat(curSelectedComp)) }) }), _jsxs(Grid, { item: true, display: "flex", children: [curSelectedComp === "general" &&
                                        _jsx(Typography, { variant: "body1", sx: styles.saveText, onClick: function () { reloadRecord(data, "Edit"); }, children: translate("common.save") }), _jsx(Typography, { variant: "body1", sx: __assign(__assign({}, styles.saveText), { paddingLeft: "2rem" }), onClick: closeDialog, children: translate("common.back") })] })] }), curSelectedComp === "general" &&
                        _jsx(GeneralComp, { params: params, translate: translate, filters: filters, setFilters: setFilters, setDialogData: setDialogData, closeDialog: closeDialog, opened: opened, reloadRecord: reloadRecord, data: data }), curSelectedComp === "groups" &&
                        _jsx(GroupComp, { params: params, translate: translate, filters: filters, setFilters: setFilters, setDialogData: setDialogData, closeDialog: closeDialog, opened: opened, reloadRecord: reloadRecord, generalData: data, data: groupData }), curSelectedComp === "users" &&
                        _jsx(UserComp, { params: params, translate: translate, filters: filters, setFilters: setFilters, setDialogData: setDialogData, closeDialog: closeDialog, opened: opened, reloadRecord: reloadRecord, generalData: data, data: userData })] })] }));
};
export default EditComp;
