var ACLData = {
    dashboard: {
        "view-general": true,
        "view-sales": true,
    },
    user: {
        "view-all": true,
        "view-sales": true,
        "view-org": true,
        "view-new": true,
        "edit-all": true,
        "edit-org": true,
        "disable-all": true,
    },
    organization: {
        "view-allOrg": true,
        "view-ownOrg": true,
        "create-org": true,
        "disable-allOrg": true,
        "disable-ownOrg": true,
        "edit-all": true,
        "edit-ownOrg": true,
        "edit-allGroup": true,
        "edit-ownGroup": true,
        "add-new": true,
        "disable-allGroup": true,
        "disable-ownGroup": true,
        "disable-orgUser": true,
    },
    subject: {
        "view-all": true,
        "view-org": true,
        "create": true,
        "edit": true,
        "delete": true,
    },
    dataExport: {
        "export-all": true,
        "export-org": true,
        "export-retinADProbScores": true,
    },
    access: {
        Dashboard: {
            add: false, view: true, edit: false, delete: false
        },
        User: {
            add: true, view: true, edit: true, delete: true
        },
        UserGroup: {
            add: false, view: false, edit: false, delete: false
        },
        Subject: {
            add: true, view: true, edit: true, delete: true
        },
        DataExport: {
            add: false, view: true, edit: false, delete: false
        }
    }
};
export { ACLData };
