import { jsx as _jsx } from "react/jsx-runtime";
import { useDroppable } from '@dnd-kit/core';
var styles = {
    outerGradSection: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "start",
        margin: "0.5rem 0",
        backgroundColor: "purple",
        // minHeight: "10vh",
        overFlowY: "scroll"
        // border: "1px solid grey",
    },
};
var Droppable = function (props) {
    var _a = useDroppable({
        id: props.id,
    }), isOver = _a.isOver, setNodeRef = _a.setNodeRef;
    var style = {
        // backgroundColor: "red",
        width: "100%",
        opacity: isOver ? 1 : 1,
        // border: '2px dashed grey',
        border: isOver ? '2px dashed grey' : "",
        minHeight: "",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        // alignItems: "stretch"
    };
    console.log(props, '==props');
    return (
    // <Grid item xs={5} style={{ ...styles.outerGradSection, opacity: isOver ? 1 : 0.5, }} justifyContent="center" ref={setNodeRef}>
    //     {/* <div ref={setNodeRef} style={style}>
    //         {props.children}
    //     </div> */}
    //     {props.children}
    // </Grid>
    _jsx("div", { ref: setNodeRef, style: style, children: props.children }));
};
export { Droppable };
