var rightPanelstyles = {
    dataExportContainer: {
        backgroundColor: "white",
        width: "100%",
        height: "100%",
        margin: "1rem",
        padding: "2rem",
        borderRadius: "20px",
        boxShadow: "2px 2px 5px lightgrey, 0 8px 10px -1px rgba(0,0,0,0.3)",
        '-moz-box-shadow': '2px 2px 5px lightgrey, 0 8px 10px -5px rgba(0,0,0,0.3)',
        '-webkit-box-shadow': '2px 2px 5px lightgrey, 0 8px 10px -5px rgba(0,0,0,0.3)',
    },
};
var dataImportstyles = {
    dataExport: {
        // backgroundColor: "red",
        // paddingY: "200px",
        width: "100%",
        overflowY: "scroll"
    },
};
export { rightPanelstyles, dataImportstyles };
