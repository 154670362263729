var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecordContext } from 'react-admin';
import { useDataProvider, useResourceContext, useNotify, useRefresh, } from 'react-admin';
import { Typography, Grid, Button, IconButton, Dialog, DialogContent, Zoom, CircularProgress, Tooltip, Checkbox, FormControlLabel, } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { dialog } from '../../utils/componentWizard';
import { pdfjs } from "react-pdf";
import { GradableImageGrid, UngradableImageGrid } from './GradImgGrid';
import { Droppable } from './dragAndDrop/Droppable';
import { DndContext } from '@dnd-kit/core';
import { styles } from './helper/FPstyles';
pdfjs.GlobalWorkerOptions.workerSrc = "//cdnjs.cloudflare.com/ajax/libs/pdf.js/".concat(pdfjs.version, "/pdf.worker.js");
import { updateAnalyzedResultTextCases, removeDuplicateCommentRows, } from './helper/FPhelper';
var downloadURI = function (uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
var Transition = React.forwardRef(function Transition(props, ref) {
    return _jsx(Zoom, __assign({ ref: ref }, props));
});
var FPComp = function (_a) {
    var filters = _a.filters, setFilters = _a.setFilters, onLoaded = _a.onLoaded, image = _a.image, setImage = _a.setImage, imageInfos = _a.imageInfos, setImageInfos = _a.setImageInfos, analyzedResultInfo = _a.analyzedResultInfo, analyzedImagesData = _a.analyzedImagesData, isLoading = _a.isLoading, handleUploadedImages = _a.handleUploadedImages, setAnalyzedResultInfo = _a.setAnalyzedResultInfo, setCurrentSubjectSection = _a.setCurrentSubjectSection, curRowImgs = _a.curRowImgs, setCurRowImgs = _a.setCurRowImgs, currentImgUploadRow = _a.currentImgUploadRow, selectedRow = _a.selectedRow, setIsLoadingUpload = _a.setIsLoadingUpload, isLoadingUpload = _a.isLoadingUpload, setDummyCompList = _a.setDummyCompList, translate = _a.translate, setIsLoadingFPAI = _a.setIsLoadingFPAI, setLoadingFPAITime = _a.setLoadingFPAITime, isLoadingFPAI = _a.isLoadingFPAI, loadingFPAITime = _a.loadingFPAITime, props = __rest(_a, ["filters", "setFilters", "onLoaded", "image", "setImage", "imageInfos", "setImageInfos", "analyzedResultInfo", "analyzedImagesData", "isLoading", "handleUploadedImages", "setAnalyzedResultInfo", "setCurrentSubjectSection", "curRowImgs", "setCurRowImgs", "currentImgUploadRow", "selectedRow", "setIsLoadingUpload", "isLoadingUpload", "setDummyCompList", "translate", "setIsLoadingFPAI", "setLoadingFPAITime", "isLoadingFPAI", "loadingFPAITime"]);
    var AnalyzedResultPreInfoIcon = analyzedResultInfo === null || analyzedResultInfo === void 0 ? void 0 : analyzedResultInfo['icon'];
    var analyzedResultInfoIcon = AnalyzedResultPreInfoIcon ? _jsx(AnalyzedResultPreInfoIcon, { style: { width: '17px', height: '17px' } }) : null;
    var _b = useState(false), openImage = _b[0], setOpenImage = _b[1];
    var _c = useState(''), openImageURL = _c[0], setOpenImageURL = _c[1];
    var _d = useState(false), canAnalyze = _d[0], setCanAnalyze = _d[1]; //conditions to check if an analyze
    //the modified curRowImgs
    var _e = useState({ update: [], comment: [], delete: [] }), modifiedCurRowImgs = _e[0], setModifiedCurRowImgs = _e[1]; //fpOneUpdate, fpOneDelete
    var _f = useState([]), modifiedCurrentImgUploadRow = _f[0], setModifiedCurrentImgUploadRow = _f[1]; //updateAttribute
    var _g = useState({ center: [], notCenter: [] }), idxArrForGrid = _g[0], setIdxArrForGrid = _g[1];
    // is dragging or not
    var _h = useState(false), isDragging = _h[0], setIsDragging = _h[1];
    var dataProvider = useDataProvider();
    var resource = "exams";
    var record = useRecordContext(props);
    var res = useResourceContext();
    var notify = useNotify();
    var refresh = useRefresh();
    var navigate = useNavigate();
    var analyzeTips = [
        "".concat(translate("pages.exam.fp.analyzeCases.cases")),
        "".concat(translate("pages.exam.fp.analyzeCases.both")),
        "".concat(translate("pages.exam.fp.analyzeCases.leftOnly")),
        "".concat(translate("pages.exam.fp.analyzeCases.rightOnly")),
    ];
    //update window sizes
    // document.body.style.zoom = "70%";
    document.body.style.background = "white";
    // handle drag drop action
    var handleDragEnd = function (item) {
        var _a, _b;
        setIsDragging(false);
        // find the selected item from curRowImgs
        var selectedDragItemIdIndex = curRowImgs.findIndex(function (obj) { var _a; return obj.id === ((_a = item === null || item === void 0 ? void 0 : item.active) === null || _a === void 0 ? void 0 : _a.id); });
        var updatedCurRowImgs = JSON.parse(JSON.stringify(curRowImgs));
        setCurRowImgs([]);
        // api params
        var selectedDragItem = curRowImgs.find(function (obj) { var _a; return obj.id === ((_a = item === null || item === void 0 ? void 0 : item.active) === null || _a === void 0 ? void 0 : _a.id); });
        var params = {
            data: {}
        };
        console.log(item, '==item 321', selectedDragItemIdIndex, item === null || item === void 0 ? void 0 : item.over, (_a = item === null || item === void 0 ? void 0 : item.over) === null || _a === void 0 ? void 0 : _a.id);
        // check what drag case
        switch ((_b = item === null || item === void 0 ? void 0 : item.over) === null || _b === void 0 ? void 0 : _b.id) {
            case null:
                setCurRowImgs(updatedCurRowImgs);
                return;
            //gradable
            case "center-left-eye":
                updatedCurRowImgs[selectedDragItemIdIndex].center = true;
                updatedCurRowImgs[selectedDragItemIdIndex].right = false;
                updatedCurRowImgs[selectedDragItemIdIndex].gradable = true;
                params.data = {
                    "center": true,
                    "right": false,
                    "gradable": true
                };
                break;
            case "center-right-eye":
                updatedCurRowImgs[selectedDragItemIdIndex].center = true;
                updatedCurRowImgs[selectedDragItemIdIndex].right = true;
                updatedCurRowImgs[selectedDragItemIdIndex].gradable = true;
                params.data = {
                    "center": true,
                    "right": true,
                    "gradable": true
                };
                break;
            case "not-center-left-eye":
                updatedCurRowImgs[selectedDragItemIdIndex].center = false;
                updatedCurRowImgs[selectedDragItemIdIndex].right = false;
                updatedCurRowImgs[selectedDragItemIdIndex].gradable = true;
                params.data = {
                    "center": false,
                    "right": false,
                    "gradable": true
                };
                break;
            case "not-center-right-eye":
                updatedCurRowImgs[selectedDragItemIdIndex].center = false;
                updatedCurRowImgs[selectedDragItemIdIndex].right = true;
                updatedCurRowImgs[selectedDragItemIdIndex].gradable = true;
                params.data = {
                    "center": false,
                    "right": true,
                    "gradable": true
                };
                break;
            //ungradable
            case "ungradable":
                updatedCurRowImgs[selectedDragItemIdIndex].gradable = false;
                params.data = {
                    "gradable": false
                };
                break;
            default:
                setCurRowImgs(updatedCurRowImgs);
                return;
        }
        setCurRowImgs([]);
        var eyeLabel = updateAnalyzedResultTextCases(updatedCurRowImgs, setAnalyzedResultInfo);
        //api
        setIsLoadingUpload(true);
        console.log(params, '==params');
        // record modified cur image rows
        var updatedModifiedCurRowImgs = JSON.parse(JSON.stringify(modifiedCurRowImgs));
        var updatedModifiedCurrentImgUploadRow = JSON.parse(JSON.stringify(modifiedCurrentImgUploadRow));
        updatedModifiedCurRowImgs.update.push({ resource: resource, params: params, id: updatedCurRowImgs[selectedDragItemIdIndex].id });
        setModifiedCurRowImgs(updatedModifiedCurRowImgs);
        setCurRowImgs(updatedCurRowImgs);
        setIsLoadingUpload(false);
        //update the eye label
        if (eyeLabel) {
            var editParams = {
                data: {
                    subject_hkid: currentImgUploadRow.subject_hkid,
                    eye: eyeLabel,
                }
            };
            updatedModifiedCurrentImgUploadRow.push({ resource: resource, params: editParams, subject_id: currentImgUploadRow.hkid_id, id: currentImgUploadRow.id });
            setModifiedCurrentImgUploadRow(updatedModifiedCurrentImgUploadRow);
        }
    };
    //handle start drag
    var handleDragStart = function (id) {
        setIsDragging(true);
        console.log('drag start');
    };
    var updateIdxArrForGrid = function (givenCurRow) {
        var updatedIdxArrForGrid = JSON.parse(JSON.stringify(idxArrForGrid));
        givenCurRow.map(function (el, idx) {
            if (el.gradable && el.center) {
                updatedIdxArrForGrid.center.push(idx);
            }
            else if (el.gradable && !el.center) {
                updatedIdxArrForGrid.notCenter.push(idx);
            }
        });
        setIdxArrForGrid(updatedIdxArrForGrid);
    };
    useEffect(function () {
        // console.log(' hi')
        setDummyCompList([{ name: "AI Results", bgColor: "#efefef" },
            { name: "Data Import", bgColor: "white" }]);
    }, [curRowImgs]);
    useEffect(function () {
        //check which result text case - on top
        updateAnalyzedResultTextCases(curRowImgs, setAnalyzedResultInfo);
        // get the idx for gridding in gradable center / nonCenter
        updateIdxArrForGrid(curRowImgs);
        console.log(currentImgUploadRow, '==currentImgUploadRow 123321', selectedRow);
    }, []);
    var handelImageGrid = function (idx, action, onChangeVal) {
        var updatedCurRowImgs = JSON.parse(JSON.stringify(curRowImgs));
        console.log('==log action, idx', idx, action, onChangeVal, updatedCurRowImgs, updatedCurRowImgs[idx]);
        var cmtParams = {
            data: { comment: onChangeVal }
        };
        var updatedModifiedCurRowImgs = JSON.parse(JSON.stringify(modifiedCurRowImgs));
        switch (action) {
            case "cmt":
                updatedCurRowImgs[idx].comment = onChangeVal;
                updatedModifiedCurRowImgs.comment.push({ resource: resource, params: cmtParams, id: updatedCurRowImgs[idx].id });
                setCurRowImgs(updatedCurRowImgs);
                break;
            case "delete":
                setCurRowImgs([]);
                updatedModifiedCurRowImgs.delete.push({ resource: resource, id: updatedCurRowImgs[idx].id });
                setIsLoadingUpload(false);
                updatedCurRowImgs.splice(idx, 1);
                setCurRowImgs(updatedCurRowImgs);
                updateAnalyzedResultTextCases(updatedCurRowImgs, setAnalyzedResultInfo);
                //update idArr
                updateIdxArrForGrid(updatedCurRowImgs);
                console.log('==log within action delete', idx, idx, updatedCurRowImgs);
                break;
        }
        setModifiedCurRowImgs(updatedModifiedCurRowImgs);
    };
    var handleOpenImage = function (image) {
        setOpenImageURL(image);
        setOpenImage(true);
    };
    var handleCloseImage = function () {
        setOpenImageURL('');
        setOpenImage(false);
    };
    //handle AI analyze part
    var handleFPAIAnalyze = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoadingFPAI(true);
                    return [4 /*yield*/, handleSave()];
                case 1:
                    _a.sent();
                    handleFinish();
                    // [back] [fpai] first api to get loading time
                    // // setLoadingFPAITime(900)
                    countDownAction(loadingFPAITime);
                    return [2 /*return*/];
            }
        });
    }); };
    // countDown for FP AI analyze
    var countDownAction = function (sec) {
        console.log(sec, '==oo');
        // TODO: Save the whole page data before trigger analysis
        // Fire API to trigger analysis
        dataProvider.triggerExamAnalysis({
            data: {
                subjectId: currentImgUploadRow.subject_id,
                examId: currentImgUploadRow.id,
                selectedOptions: selectedOptions
            }
        }).then(function (data) {
            console.log("trigger data", data);
            var content = data.data;
            // TODO: Stop timer
            if (content.result) {
                // TODO: print pdf
                var examData = content.examData;
                var analysisData = content.analysisData;
                var url = '/admin/fp/report';
                var data_1 = __assign({}, content);
                data_1.subjectInfos = selectedRow;
                data_1.examData.examId = currentImgUploadRow.id;
                data_1.examData.subjectId = currentImgUploadRow.subject_id;
                localStorage.setItem('report_cache_data', JSON.stringify(data_1));
                var newWindow = window.open(url, '_blank');
            }
            setIsLoadingFPAI(false);
        }).catch(function (error) {
            console.error(error);
            notify('Error in exam analysis');
        });
        var updatedSec = sec * 1000;
        setTimeout(function () {
            setIsLoadingFPAI(false);
            // // add "exist" in pdf
            // let editParams: any = {
            //     data: {
            //         subject_hkid: currentImgUploadRow.subject_hkid,
            //         ai_report: ["exist"],
            //     }
            // }
            // dataProvider.updateAttribute(resource, editParams,
            //     currentImgUploadRow.hkid_id, currentImgUploadRow.id)
            //     .then(() => {
            //         refresh()
            //         notify('Exam updated')
            //     }).catch((error) => {
            //         // Handle any errors that occurred
            //         console.error(error);
            //         notify('')
            //     });
            // open pdf
            // refresh()
            // console.log(currentImgUploadRow, '==crr')
            // window.open(samplePDF)
        }, updatedSec);
        return function () {
            clearTimeout(countdown);
        };
    };
    // useEffect(() => { console.log('9') },
    //     [isLoadingFPAI
    //     ])
    var handleFinish = function () {
        setImage(null);
        //redirect to "AI Fesults"
        setCurrentSubjectSection('AI Results');
        setDummyCompList([{ name: "AI Results" }]);
        refresh();
    };
    var handleSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var updatedModifiedCurRowImgs, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoadingUpload(true);
                    updatedModifiedCurRowImgs = modifiedCurRowImgs;
                    updatedModifiedCurRowImgs.comment = removeDuplicateCommentRows(updatedModifiedCurRowImgs.comment);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, 7, 8]);
                    // fp comment update
                    return [4 /*yield*/, Promise.all(updatedModifiedCurRowImgs.comment.map(function (el) { return dataProvider.fpOneUpdate(el.resource, el.params, el.id); }))];
                case 2:
                    // fp comment update
                    _a.sent();
                    // fp (drag/drop) update
                    return [4 /*yield*/, Promise.all(updatedModifiedCurRowImgs.update.map(function (el) { return dataProvider.fpOneUpdate(el.resource, el.params, el.id); }))];
                case 3:
                    // fp (drag/drop) update
                    _a.sent();
                    // fp delete update
                    return [4 /*yield*/, Promise.all(updatedModifiedCurRowImgs.delete.map(function (el) { return dataProvider.fpOneDelete(el.resource, el.id); }))];
                case 4:
                    // fp delete update
                    _a.sent();
                    // exams row update
                    return [4 /*yield*/, Promise.all(modifiedCurrentImgUploadRow.map(function (el) { return dataProvider.updateAttribute(el.resource, el.params, el.subject_id, el.id); }))];
                case 5:
                    // exams row update
                    _a.sent();
                    return [3 /*break*/, 8];
                case 6:
                    err_1 = _a.sent();
                    console.log(err_1, '==err');
                    notify('Images update fail');
                    return [3 /*break*/, 8];
                case 7:
                    setIsLoadingUpload(false);
                    notify('Images updated');
                    refresh();
                    return [7 /*endfinally*/];
                case 8:
                    console.log('==log handle save 12', updatedModifiedCurRowImgs);
                    return [2 /*return*/];
            }
        });
    }); };
    // New added
    var _j = useState({
        dr: true,
        amd: false,
        glaucoma: false,
    }), selectedOptions = _j[0], setSelectedOptions = _j[1];
    // New added
    var handleCheckboxChange = function (event) {
        var _a;
        var _b = event.target, name = _b.name, checked = _b.checked;
        // 如果用户尝试取消选中最后一个选项，保持至少一个选项选中
        if (checked || Object.values(__assign(__assign({}, selectedOptions), (_a = {}, _a[name] = checked, _a))).some(function (option) { return option; })) {
            setSelectedOptions(function (prev) {
                var _a;
                return (__assign(__assign({}, prev), (_a = {}, _a[name] = checked, _a)));
            });
        }
    };
    return (_jsxs(Grid, { item: true, style: styles.container, children: [_jsx(Grid, { item: true, display: "flex", flexWrap: "wrap", justifyContent: "space-between", children: _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", padding: 1, justifyContent: "end", children: [_jsx(Grid, { item: true, paddingX: 1, children: _jsxs(Button, { variant: "contained", disabled: isLoadingUpload, onClick: function () {
                                    handleSave();
                                }, children: [!isLoadingUpload && "Save", isLoadingUpload && _jsx(CircularProgress, { color: "grey", size: 24 })] }) }), _jsx(Grid, { item: true, paddingX: 1, children: _jsx(Button, { variant: "contained", onClick: function () {
                                    handleFinish();
                                }, children: "Close" }) }), _jsxs(Grid, { item: true, paddingX: 1, children: [console.log('==log analyzedResultInfo.type', analyzedResultInfo === null || analyzedResultInfo === void 0 ? void 0 : analyzedResultInfo.type), _jsx(Button, { variant: "contained", disabled: ((analyzedResultInfo === null || analyzedResultInfo === void 0 ? void 0 : analyzedResultInfo.type) === 'none' || isLoadingFPAI) ? true : false, onClick: handleFPAIAnalyze, children: "Analyze" }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: selectedOptions.dr, onChange: handleCheckboxChange, name: "dr", style: { marginLeft: '8px' } }), label: "DR" }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: selectedOptions.amd, onChange: handleCheckboxChange, name: "amd" }), label: "AMD" }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: selectedOptions.glaucoma, onChange: handleCheckboxChange, name: "glaucoma" }), label: "Glaucoma" })] }), _jsx(Tooltip, { title: _jsx(_Fragment, { children: analyzeTips.map(function (el, idx) {
                                    return (_jsx(Typography, { children: el }));
                                }) }), placement: "top", children: _jsx(IconButton, { children: _jsx(InfoIcon, {}) }) })] }) }), isLoadingUpload && _jsx(Grid, { item: true, children: _jsxs(Grid, { item: true, xs: 12, paddingY: 0.2, display: "flex", flexWrap: "wrap", justifyContent: "center", children: [_jsx(CircularProgress, { color: "grey", size: 24 }), _jsx(Typography, { variant: "body1", color: "grey", paddingX: 1, children: "Loading " })] }) }), (image && !isLoadingUpload) &&
                _jsxs(Grid, { item: true, style: styles.containerAfterAnlyze, children: [_jsxs(Grid, { item: true, style: __assign(__assign({}, styles.anaylzedText), { backgroundColor: analyzedResultInfo === null || analyzedResultInfo === void 0 ? void 0 : analyzedResultInfo.bgColor }), marginY: 1, children: [analyzedResultInfoIcon && _jsx(Typography, { variant: "body1", paddingTop: "3px", paddingRight: "5px", children: analyzedResultInfoIcon }), _jsx(Typography, { variant: "body1", children: analyzedResultInfo === null || analyzedResultInfo === void 0 ? void 0 : analyzedResultInfo.text })] }), _jsx(DndContext, { onDragEnd: handleDragEnd, onDragStart: handleDragStart, children: _jsxs(Grid, { item: true, style: { width: "100%", backgroundColor: "" }, children: [_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", padding: 0.5, style: { backgroundColor: "#e0eefb", }, children: [_jsx(Grid, { item: true, xs: 12, style: styles.withBorder, justifyContent: "center", children: "Gradable FP" }), _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", padding: 0.5, style: { backgroundColor: "#e0eefb", width: "100%", }, children: [_jsx(Grid, { item: true, xs: 2, style: styles.titleWithBorder, justifyContent: "center", children: "Macula-centered" }), _jsxs(Grid, { item: true, xs: 5, style: __assign(__assign({}, styles.droppableOuterGrid), { backgroundColor: "" }), children: [_jsx(Grid, { item: true, xs: 12, style: __assign(__assign({}, styles.droppableGrid), { backgroundColor: "", width: " 100%" }), justifyContent: "center", children: _jsx(Droppable, { id: "center-left-eye", existArr: curRowImgs === null || curRowImgs === void 0 ? void 0 : curRowImgs.filter(function (el) { return el.gradable && el.center && !el.right; }), children: "Left Eye" }) }), _jsx("div", { style: { display: "flex", flexWrap: "wrap", justifyContent: "center", backgroundColor: "" }, children: curRowImgs === null || curRowImgs === void 0 ? void 0 : curRowImgs.map(function (el, idx) {
                                                                    if (el.gradable && el.center && !el.right) {
                                                                        return (_jsx(GradableImageGrid, { comment: el.comment, id: el.id, imgName: el.original_name, eyeType: el.right ? "right" : "left", image: el === null || el === void 0 ? void 0 : el.img, handleOpenImage: handleOpenImage, idx: idx, handelImageGrid: handelImageGrid, isDragging: isDragging }));
                                                                    }
                                                                }) })] }), _jsxs(Grid, { item: true, xs: 5, style: __assign(__assign({}, styles.droppableOuterGrid), { backgroundColor: "" }), children: [_jsx(Grid, { item: true, xs: 12, style: __assign(__assign({}, styles.droppableGrid), { backgroundColor: "", width: " 100%" }), justifyContent: "center", children: _jsx(Droppable, { id: "center-right-eye", existArr: curRowImgs === null || curRowImgs === void 0 ? void 0 : curRowImgs.filter(function (el) { return el.gradable && el.center && el.right; }), children: "Right Eye" }) }), _jsx("div", { style: { display: "flex", flexWrap: "wrap", justifyContent: "center" }, children: curRowImgs === null || curRowImgs === void 0 ? void 0 : curRowImgs.map(function (el, idx) {
                                                                    if (el.gradable && el.center && el.right) {
                                                                        return (_jsx(GradableImageGrid, { comment: el.comment, id: el.id, imgName: el.original_name, eyeType: el.right ? "right" : "left", image: el === null || el === void 0 ? void 0 : el.img, handleOpenImage: handleOpenImage, idx: idx, handelImageGrid: handelImageGrid, isDragging: isDragging }));
                                                                    }
                                                                }) })] })] }), _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", padding: 0.5, style: { backgroundColor: "#e0eefb", width: "100%" }, children: [_jsx(Grid, { item: true, xs: 2, style: styles.titleWithBorder, justifyContent: "center", children: "Not Macula-centered" }), _jsxs(Grid, { item: true, xs: 5, style: __assign(__assign({}, styles.droppableOuterGrid), { backgroundColor: "" }), children: [_jsx(Grid, { item: true, xs: 12, style: styles.droppableGrid, justifyContent: "center", children: _jsx(Droppable, { id: "not-center-left-eye", existArr: curRowImgs === null || curRowImgs === void 0 ? void 0 : curRowImgs.filter(function (el) { return el.gradable && !el.center && !el.right; }), children: "Left Eye" }) }), _jsx("div", { style: { display: "flex", flexWrap: "wrap", justifyContent: "center" }, children: curRowImgs === null || curRowImgs === void 0 ? void 0 : curRowImgs.map(function (el, idx) {
                                                                    if (el.gradable && !el.center && !el.right) {
                                                                        return (_jsx(GradableImageGrid, { comment: el.comment, id: el.id, imgName: el.original_name, eyeType: el.right ? "right" : "left", image: el === null || el === void 0 ? void 0 : el.img, handleOpenImage: handleOpenImage, idx: idx, handelImageGrid: handelImageGrid, isDragging: isDragging }));
                                                                    }
                                                                }) })] }), _jsxs(Grid, { item: true, xs: 5, style: __assign(__assign({}, styles.droppableOuterGrid), { backgroundColor: "" }), children: [_jsx(Grid, { item: true, xs: 12, style: styles.droppableGrid, justifyContent: "center", children: _jsx(Droppable, { id: "not-center-right-eye", existArr: curRowImgs === null || curRowImgs === void 0 ? void 0 : curRowImgs.filter(function (el) { return el.gradable && !el.center && el.right; }), children: "Right Eye" }) }), _jsx("div", { style: { display: "flex", flexWrap: "wrap", justifyContent: "center" }, children: curRowImgs === null || curRowImgs === void 0 ? void 0 : curRowImgs.map(function (el, idx) {
                                                                    if (el.gradable && !el.center && el.right) {
                                                                        return (_jsx(GradableImageGrid, { comment: el.comment, id: el.id, imgName: el.original_name, eyeType: el.right ? "right" : "left", image: el === null || el === void 0 ? void 0 : el.img, handleOpenImage: handleOpenImage, idx: idx, handelImageGrid: handelImageGrid, isDragging: isDragging }));
                                                                    }
                                                                }) })] })] })] }), _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", 
                                        // alignItems="stretch" style={{ backgroundColor: "white", width: "100%" }}
                                        style: __assign(__assign({}, styles.droppableGrid), { backgroundColor: "" }), children: [_jsx(Grid, { item: true, xs: 12, justifyContent: "stretch", style: __assign(__assign({}, styles.droppableGrid), { backgroundColor: "#ffd1d1" }), padding: 0.5, children: _jsx(Droppable, { id: "ungradable", existArr: curRowImgs === null || curRowImgs === void 0 ? void 0 : curRowImgs.filter(function (el) { return !el.gradable; }), children: "Ungradable FP" }) }), _jsx("div", { style: { display: "flex", flexWrap: "wrap" }, children: curRowImgs === null || curRowImgs === void 0 ? void 0 : curRowImgs.map(function (el, idx) {
                                                    var _a;
                                                    if (!el.gradable) {
                                                        return (_jsx(UngradableImageGrid, { id: el.id, comment: (_a = el === null || el === void 0 ? void 0 : el.comment) !== null && _a !== void 0 ? _a : "", idx: idx, imgName: el.original_name, handelImageGrid: handelImageGrid, image: el === null || el === void 0 ? void 0 : el.img, isDragging: isDragging }));
                                                    }
                                                }) })] })] }) })] }), openImage &&
                _jsx(Grid, { item: true, style: { width: "300px", height: "300px" }, children: _jsxs(Dialog, { style: {}, open: openImage, TransitionComponent: Transition, onClose: handleCloseImage, keepMounted: true, children: [_jsx(dialog.header, { title: "Gradable image", handleClose: handleCloseImage }), _jsx(DialogContent, { children: _jsx("img", { style: { maxWidth: "100%", height: 'auto' }, src: openImageURL, alt: 'Gradable image', width: "500", height: "500" }) })] }) })] }));
};
export default FPComp;
