var dummyData = [
    {
        id: "123",
        organizations: "CUHK OVS",
        userGroup: ["Group A", "Group b"],
        username: "234",
        lastname: "chan",
        firstname: "len",
        email: "xxx@123.com",
        gender: "M",
        status: "Active",
        createdAt: "2024-07-15T17:18:39.290Z",
        updatedAt: "2024-07-15T17:18:39.290Z",
    },
];
export { dummyData };
