var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form, DateInput as RADateInput, minValue as RAminValue } from 'react-admin';
import { Box, Grid } from '@mui/material';
import { filter } from '../utils/componentWizard';
var Spacer = function () { return _jsx(Box, { mb: 1, children: "\u00A0" }); };
var FilterList = function (_a) {
    var _b;
    var search = _a.search, isLoading = _a.isLoading, filters = _a.filters, setFilters = _a.setFilters, params = _a.params, translate = _a.translate;
    var SubjectsDatePicker = function (_a) {
        var filters = _a.filters, setFilters = _a.setFilters, name = _a.name, label = _a.label, minWidth = _a.minWidth, minDate = _a.minDate, maxDate = _a.maxDate, size = _a.size, notNeedRequired = _a.notNeedRequired, gridBGColor = _a.gridBGColor, width = _a.width;
        var gridStyle = { marginY: "1rem", };
        if (gridBGColor) {
            gridStyle['backgroundColor'] = gridBGColor;
            gridStyle['padding'] = "0.6rem";
            gridStyle['borderRadius'] = "10px";
        }
        ;
        return (_jsx(Grid, { item: true, style: gridStyle, children: _jsx(Form, { children: _jsx(RADateInput, { source: label, variant: "outlined", 
                    // minDate={minDate ?? null}
                    //[back] min and max date not working
                    validate: RAminValue(new Date('2024-06-06')), 
                    // maxDate={maxDate  ?? null}
                    onChange: function (e) {
                        var newFilter = __assign({}, filters);
                        var date = new Date(e.target.value).toLocaleDateString('en-US');
                        if (date == "Invalid Date") {
                            delete newFilter[name];
                        }
                        else {
                            newFilter[name] = date;
                        }
                        setFilters(newFilter);
                        sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(newFilter));
                    }, defaultValue: (filters === null || filters === void 0 ? void 0 : filters[name]) ? new Date(filters[name]) : null, sx: {
                        opacity: 0.6,
                        width: "9em",
                        '& .MuiInputBase-root': {
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                            boxShadow: '0px 6px 8px gray',
                            height: "2.7em",
                            fontWeight: 900,
                            fontSize: "1.1em",
                            '& input': {
                                opacity: 0.7,
                                paddingTop: '1em',
                            },
                        },
                        '& .MuiFormLabel-root': {},
                    } }) }) }));
    };
    var sessonStorageJSON = (_b = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _b !== void 0 ? _b : {};
    var updatedFilters = __assign(__assign({}, filters), sessonStorageJSON);
    return (_jsxs(Grid, { container: true, spacing: 1, justifyContent: "start", children: [_jsx(filter.textField, { filters: filters, setFilters: setFilters, label: translate("pages.userList.fields.id"), name: "id", width: 140 }), _jsx(filter.dropDownList, { filters: filters, setFilters: setFilters, label: translate("pages.userList.fields.userGroup"), name: "userGroups", minWidth: 160, options: params }), _jsx(filter.dropDownList, { filters: filters, setFilters: setFilters, label: translate("pages.userList.fields.organization"), name: "organizations", minWidth: 160, options: params }), _jsx(SubjectsDatePicker, { filters: updatedFilters, setFilters: setFilters, label: translate("pages.userList.fields.createdAt"), name: "createdAt", xs: 12, md: 4, minWidth: 180, minDate: new Date(2019, 8, 1), maxDate: new Date(), notNeedRequired: true }), _jsx(SubjectsDatePicker, { filters: updatedFilters, setFilters: setFilters, label: translate("pages.userList.fields.updatedAt"), name: "updatedAt", xs: 12, md: 4, minWidth: 180, minDate: new Date(2019, 8, 1), maxDate: new Date(), notNeedRequired: true })] }));
};
export default FilterList;
