//  MUI
var LanguageChoices = [
    { value: 'English', label: 'English' },
    { value: '中文', label: '中文' },
];
var AIModelMuiChoices = [
    { value: 'FP + OCT', label: 'FP + OCT' },
    { value: 'FP', label: 'FP' },
    { value: 'OCT', label: 'OCT' }
];
export { LanguageChoices };
