var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Datagrid, DateField, ExportButton, List, SelectColumnsButton, TextField, TopToolbar, WrapperField, FunctionField, useDataProvider, useResourceContext, useNotify, useRefresh, useTranslate, } from 'react-admin';
import { Typography, Grid, IconButton } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { general } from '../utils/componentWizard';
import CreateDialog from './CreateDialog';
import { orgStyles as styles } from './helper/styles';
import Tooltip from '@mui/material/Tooltip';
import FilterList from './FilterList.tsx';
import { ACLData } from '../utils/dummyData';
import { subjectIdHelper } from '../utils/helper';
import EditComp from './EditComp';
var ListActions = function () { return (_jsxs(TopToolbar, { children: [_jsx(SelectColumnsButton, {}), _jsx(ExportButton, {})] })); };
var OrganizationList = function (props) {
    var location = useLocation();
    var navigate = useNavigate();
    var resource = useResourceContext(props);
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    var translate = useTranslate();
    // const [data, setData] = useState(dummyData); //data table data
    // const resource = "UserList";
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(ACLData), acessControlList = _b[0], setAcessControlList = _b[1];
    var _c = useState({
    // analyzeFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // analyzeToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    }), filters = _c[0], setFilters = _c[1];
    var _d = useState({}), params = _d[0], setParams = _d[1]; //data table data
    var _e = useState({}), selectedRow = _e[0], setSelectedRow = _e[1];
    var _f = useState({}), selectedViewOrg = _f[0], setSelectedViewOrg = _f[1]; //when onclick the "eye" btn on list
    // dialog for org
    var _g = useState(false), dialogOpened = _g[0], setDialogOpened = _g[1];
    var _h = useState({}), dialogData = _h[0], setDialogData = _h[1];
    // dialog for create
    var _j = useState(false), createDialogOpened = _j[0], setCreateDialogOpened = _j[1];
    var closeDialog = function () {
        setDialogOpened(false);
    };
    var delAction = function (record) {
        var params = {
            data: {
                givenId: record.id,
            }
        };
        dataProvider.delete(resource, params)
            .then(function () {
            refresh();
            notify('Subject deleted');
        });
    };
    var setDialogDataAction = function (record) {
        var _a;
        setDialogData(__assign({ submit: null, dialogType: (record === null || record === void 0 ? void 0 : record.id) ? "Edit" : "Create", id: (_a = record === null || record === void 0 ? void 0 : record.id) !== null && _a !== void 0 ? _a : null }, record
        // hkid: record?.hkid ?? null,
        // lastname: record?.lastname ?? null,
        // firstname: record?.firstname ?? null,
        // gender: record?.gender ?? null,
        // dateOfBirth: record?.dateOfBirth ?? null,
        ));
    };
    var openEditDialog = function (record) {
        console.log('==log record 123', record);
        setDialogDataAction(record);
        // formik?.current?.resetForm();
        setDialogOpened(true);
    };
    // create
    var openCreateDialog = function (record) {
        console.log('==log create record 123', record);
        setDialogDataAction(record);
        // formik?.current?.resetForm();
        setCreateDialogOpened(true);
    };
    var reloadRecord = function (values, dialogType) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedPassword, updatedConfirmpw, params_1;
        return __generator(this, function (_a) {
            setIsLoading(true);
            try {
                console.log('==log submit from dialog', values, dialogType);
                updatedPassword = (!values.password || values.password === "") ? null : values.password;
                updatedConfirmpw = (!values.confirmpw || values.confirmpw === "") ? null : values.confirmpw;
                if (updatedPassword != updatedConfirmpw) {
                    notify("Password not the same");
                    return [2 /*return*/];
                }
                params_1 = {
                    data: {
                        "name": values.orgName,
                    }
                };
                switch (dialogType) {
                    case "Create":
                        console.log(params_1, '==params');
                        dataProvider.create(resource, params_1)
                            .then(function () {
                            refresh();
                            notify('Organization created');
                            setIsLoading(false);
                        }).catch(function (error) {
                            // Handle any errors that occurred
                            console.error(error);
                            refresh();
                            notify('Organization create failed');
                            setIsLoading(false);
                        });
                        break;
                    case "Edit":
                        params_1.data.id = values.id;
                        // params.data.givenId = values.id;
                        dataProvider.updateOne(resource, params_1, values.id)
                            .then(function () {
                            refresh();
                            notify('Organization updated');
                            setIsLoading(false);
                        }).catch(function (error) {
                            // Handle any errors that occurred
                            console.error(error);
                            refresh();
                            notify('Organization update failed');
                            setIsLoading(false);
                        });
                        break;
                }
                setDialogData(__assign({}, values));
            }
            catch (err) {
                console.log(err, '==err');
                refresh();
                notify('User update fail');
                setIsLoading(false);
                // dispatch(actionTypes.SNACKBAR_ERROR);
            }
            return [2 /*return*/];
        });
    }); };
    useEffect(function () { return __awaiter(void 0, void 0, void 0, function () {
        var sessonStorageJSON, cachedAuth, settingsParams, newParams, orgParams, orgData;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
                    if (sessonStorageJSON) {
                        setFilters(__assign(__assign({}, filters), sessonStorageJSON));
                    }
                    sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(__assign(__assign({}, filters), sessonStorageJSON)));
                    searchFilterGroup();
                    cachedAuth = (_b = JSON.parse(localStorage.getItem('auth'))) !== null && _b !== void 0 ? _b : {};
                    settingsParams = {};
                    if (cachedAuth) {
                        settingsParams.id = cachedAuth.id;
                        dataProvider.getOneWithoutDataJSON("users", settingsParams)
                            .then(function (rtnData) {
                            var _a;
                            var rtnACL = __assign({}, (_a = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _a === void 0 ? void 0 : _a.access);
                            var newACList = __assign({}, ACLData);
                            newACList.access = __assign({}, rtnACL);
                            setAcessControlList(newACList);
                        }).catch(function (error) {
                            // Handle any errors that occurred
                            console.error(error);
                        });
                    }
                    newParams = __assign({}, params);
                    orgParams = {
                        pagination: { page: 1, perPage: 25 },
                        sort: { field: "id", order: "ASC" },
                        // filter: updatedFilters //[back][0715] resume this
                        filter: {}
                    };
                    return [4 /*yield*/, dataProvider.getListNoFilter("organizations", orgParams)];
                case 1:
                    orgData = (_d.sent());
                    orgData = (_c = orgData === null || orgData === void 0 ? void 0 : orgData.data) === null || _c === void 0 ? void 0 : _c.map(function (item) { return ({ id: item.id, name: item.orgName, value: item.orgName }); });
                    newParams['organizations'] = orgData;
                    setParams(newParams);
                    console.log(orgData, '==orgData');
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, []);
    var searchFilterGroup = function () {
        var _a;
        setIsLoading(true);
        var sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
        // update the filter labels
        // let updatedFilters = JSON.parse(JSON.stringify(filters));
        // if (updatedFilters?.educationLevel) {
        //     updatedFilters.educationLevel = EducationLevelMuiChoices[filters.educationLevel].label
        // }
        // if (updatedFilters?.gender) {
        //     updatedFilters.gender = GenderMuiChoices[filters.gender].label
        // }
        var params = {
            pagination: { page: 1, perPage: 25 },
            sort: { field: "id", order: "ASC" },
            // filter: updatedFilters //[back][0715] resume this
            filter: __assign(__assign({}, filters), sessonStorageJSON)
        };
        console.log('==log params on subject list', params);
        dataProvider.getList(resource, params)
            .then(function (data) {
            refresh();
            notify('Users fetched');
            setIsLoading(false);
        }).catch(function (error) {
            // Handle any errors that occurred
            console.error(error);
            setIsLoading(false);
        });
    };
    return (_jsxs(Grid, { container: true, style: styles.UserList, sx: { width: '100%' }, children: [!dialogOpened &&
                _jsxs(Grid, { item: true, md: 12, xs: 12, sx: { marginTop: '1em', width: '100%' }, children: [_jsx(general.breadcrumb, { trail: ["User Management", "".concat(translate("pages.organizations.name"))] }), _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", sx: { width: '100%' }, children: [_jsxs(Grid, { item: true, display: "flex", alignItems: "center", justifyContent: "start", sx: { width: '100%' }, children: [_jsx(Typography, { variant: "h5", sx: {
                                                paddingRight: '15px',
                                                fontWeight: "900",
                                                marginBottom: '0.5em',
                                            }, children: translate("pages.organizations.name") }), acessControlList.subject.create &&
                                            _jsxs(Typography, { variant: "body", sx: {
                                                    color: "#1976d2",
                                                    cursor: "pointer",
                                                    fontWeight: "900",
                                                    marginBottom: '0.5em',
                                                    transition: 'all 0.3s ease',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    '&:hover': {
                                                        transform: 'scale(1.1) translateX(10px)',
                                                    },
                                                    '&:hover .MuiSvgIcon-root': {
                                                        transform: 'scale(1.1)',
                                                    }
                                                }, onClick: openCreateDialog, children: [_jsx(AddBoxIcon, { sx: { marginRight: '0.3em', transition: 'all 0.3s ease' } }), translate("pages.organizations.addNew")] })] }), _jsx(FilterList, { translate: translate, search: searchFilterGroup, isLoading: isLoading, params: params, filters: filters, setFilters: setFilters }), _jsx(List
                                // filterDefaultValues={{ status: 'ordered' }}
                                , { 
                                    // filterDefaultValues={{ status: 'ordered' }}
                                    filterDefaultValues: filters, sort: { field: 'id', order: 'DESC' }, 
                                    // debounce={handleListChange}
                                    // perPage={25}
                                    empty: false, 
                                    // filters={orderFilters}
                                    actions: _jsx(ListActions, {}), sx: { width: '100%' }, children: _jsxs(Datagrid, { bulkActionButtons: false, children: [_jsx(FunctionField, { source: "id", label: translate("pages.organizations.fields.id"), render: function (record) {
                                                    return (_jsx(Typography, { children: subjectIdHelper(record.id) }));
                                                } }), _jsx(TextField, { source: "orgName", label: "Organization Name" }), _jsx(DateField, { source: "createdAt", label: "Created At" }), _jsx(DateField, { source: "updatedAt", label: "Updated At" }), _jsx(TextField, { source: "groupNum", label: "Number of Groups" }), _jsx(TextField, { source: "userNum", label: "Number of Users" }), _jsx(WrapperField, { label: translate("pages.subject.fields.actions"), children: _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", children: [_jsx(FunctionField, { source: "", label: "", render: function (record) {
                                                                return (_jsx(Grid, { item: true, onClick: function () {
                                                                        // openEditDialog(record)
                                                                        // setDialogDataAction(record)
                                                                        console.log('==logggg', record);
                                                                        setSelectedViewOrg(record);
                                                                        openEditDialog(record);
                                                                        // navigate('/admin/exams', { state: record })
                                                                    }, children: _jsx(Tooltip, { title: "view + edit organization", children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(PreviewIcon, { fontSize: "small" }) }) }) }));
                                                            } }), (acessControlList.organization["disable-allOrg"] || acessControlList.organization["disable-ownOrg"]) && _jsx(FunctionField, { source: "", label: "", render: function (record) {
                                                                return (_jsx(Grid, { item: true, onClick: function () { delAction(record); }, children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(DeleteIcon, { fontSize: "small" }) }) }));
                                                            } })] }) })] }) })] })] }), createDialogOpened && _jsx(CreateDialog, { opened: createDialogOpened, data: dialogData, params: params, 
                // openDialog={openDialog}
                translate: translate, closeDialog: function () { setCreateDialogOpened(false); }, reloadRecord: reloadRecord }), dialogOpened &&
                _jsxs(Grid, { item: true, md: 12, xs: 12, sx: { width: '100%' }, children: [_jsx(general.breadcrumb, { trail: ["User Management", "".concat(translate("pages.organizations.name")), selectedViewOrg.orgName ? "#".concat(selectedViewOrg.orgName) : ""] }), _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", sx: { width: '100%' }, children: [_jsx(Typography, { variant: "h5", sx: { paddingRight: '10px' }, children: translate("pages.organizations.name") }), _jsx(Typography, { variant: "h5", sx: { paddingRight: '10px' }, children: selectedViewOrg.orgName ? "#" + selectedViewOrg.orgName : " " })] }), _jsx(EditComp, { resource: resource, data: dialogData, closeDialog: closeDialog, opened: dialogOpened, reloadRecord: reloadRecord, setDialogData: setDialogData, selectedViewOrg: selectedViewOrg })] })] }));
};
export default OrganizationList;
