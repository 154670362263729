var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { flattenObjectWithUnderscores, excludeFromObj } from '../utils/helper';
import { useDataProvider, useNotify, useRefresh, useTranslate, } from 'react-admin';
import { Grid } from '@mui/material';
import ExportList from './ExportList';
import RightPanel from "./RightPanel";
import ExportDialog from './ExportDialog';
import { dataImportstyles as styles } from './helper/styles';
import { dummyDataExport, dummyPdfData } from './helper/dummyData';
import ExportFunc from './helper/ExportFunc';
import { ACLData } from '../utils/dummyData';
var DataExport = function () {
    var location = useLocation();
    var navigate = useNavigate();
    var resource = "subjects";
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    var translate = useTranslate();
    var _a = useState([]), data = _a[0], setData = _a[1]; //data table data
    // const resource = "dataexport";
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(ACLData), acessControlList = _c[0], setAcessControlList = _c[1];
    var _d = useState({
    // analyzeFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // analyzeToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    }), filters = _d[0], setFilters = _d[1];
    // dialog for preview export
    var _e = useState(false), dialogOpened = _e[0], setDialogOpened = _e[1];
    var _f = useState({}), dialogData = _f[0], setDialogData = _f[1];
    // data table header
    var _g = useState([
        {
            "id": translate("pages.subject.fields.id"),
            "hkid": translate("pages.subject.fields.hkid"),
            "firstname": translate("pages.subject.fields.firstname"),
            "lastname": translate("pages.subject.fields.lastname"),
            "gender": translate("pages.subject.fields.gender"),
            "educationLevel": translate("pages.subject.fields.educationLevel"),
            "dateOfBirth": translate("pages.subject.fields.dateOfBirth"),
            "isHeader": true,
            "selected": false
        }
    ]), dataGridHeader = _g[0], setDataGridHeader = _g[1];
    // right panel field
    var _h = useState({
        subjectInformation: false,
        images: {
            fp: false,
            oct: false,
        },
        retinADReports: {
            fp: false,
            oct: false,
            fpAndoct: false,
        },
        retinADProbabilityScores: {
            fp: false,
            oct: false,
            fpAndoct: false,
        },
        questionnaireResults: false,
        // criteria
        examDate: false,
        analyzeDate: false,
    }), exportFields = _h[0], setExportFields = _h[1];
    //  right panel field - criteria - selected date
    var _j = useState({
    // analyzeFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // analyzeToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    }), exportDateInfoFields = _j[0], setExportDateInfoFields = _j[1];
    // data that will pass to export
    var _k = useState(dummyDataExport), dataToExport = _k[0], setDataToExport = _k[1]; //data table data
    //pdf blob for dataToExport
    // const [pdfArrExist, setPdfArrExist] = useState(true); // to know if there will be pdf
    var _l = useState(dummyPdfData), pdfArr = _l[0], setPdfArr = _l[1]; //all fp pdf return from BE
    var _m = useState({}), pdfBlobs = _m[0], setPdfBlobs = _m[1];
    //for export dialog - first click - preview information
    var previewExport = function () {
        var dataExport;
        var updatedDialogData = {
            selected: data.filter(function (item) { return item.selected === true; }).length,
            dataExport: exportFields,
            criteria: exportDateInfoFields
        };
        setDialogData(updatedDialogData);
        setDialogOpened(true);
    };
    var checkIfExportFieldHasSelections = function (obj) {
        return Object.keys(obj).some(function (key) {
            // ignore date selection
            if (key === "examDate" || key === "analyzeDate") {
                return false; // Ignoring examDate and analyzeDate
            }
            if (typeof obj[key] === "object") {
                return checkIfExportFieldHasSelections(obj[key]);
            }
            return obj[key] === true;
        });
    };
    //for export confirm
    var reloadRecord = function () { return __awaiter(void 0, void 0, void 0, function () {
        var selectedExportFields, exportFieldsFilter, subjectIdArr, params, newDataToExport;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            selectedExportFields = checkIfExportFieldHasSelections(exportFields);
            if (!selectedExportFields) {
                notify("Please select field(s)!");
                return [2 /*return*/];
            }
            setIsLoading(true);
            exportFieldsFilter = flattenObjectWithUnderscores(__assign({}, exportFields));
            exportFieldsFilter = excludeFromObj(exportFieldsFilter, ["examDate", "analyzeDate"]);
            //check for the date
            if (exportFields.examDate && exportDateInfoFields.examFromDate) {
                exportFieldsFilter.examFromDate = (_a = exportDateInfoFields === null || exportDateInfoFields === void 0 ? void 0 : exportDateInfoFields.examFromDate) !== null && _a !== void 0 ? _a : "";
            }
            if (exportFields.examDate && exportDateInfoFields.examToDate) {
                exportFieldsFilter.examToDate = (_b = exportDateInfoFields === null || exportDateInfoFields === void 0 ? void 0 : exportDateInfoFields.examToDate) !== null && _b !== void 0 ? _b : "";
            }
            if (exportFields.analyzeDate && exportDateInfoFields.analyzeFromDate) {
                exportFieldsFilter.analyzeFromDate = (_c = exportDateInfoFields === null || exportDateInfoFields === void 0 ? void 0 : exportDateInfoFields.analyzeFromDate) !== null && _c !== void 0 ? _c : "";
            }
            if (exportFields.analyzeDate && exportDateInfoFields.analyzeToDate) {
                exportFieldsFilter.analyzeToDate = (_d = exportDateInfoFields === null || exportDateInfoFields === void 0 ? void 0 : exportDateInfoFields.analyzeToDate) !== null && _d !== void 0 ? _d : "";
            }
            subjectIdArr = (_e = data === null || data === void 0 ? void 0 : data.filter(function (el) { return el.selected === true; })) === null || _e === void 0 ? void 0 : _e.map(function (el) { return String(el.id); });
            params = {
                data: {
                    subject_id: subjectIdArr,
                    // subject_id: ["78335967-e8b4-46c9-bb49-f6d93376e4e4"],
                    filters: exportFieldsFilter
                }
            };
            newDataToExport = [];
            dataProvider.dataExport(resource, params)
                .then(function (rtnData) { return __awaiter(void 0, void 0, void 0, function () {
                var objIndex, _i, newDataToExport_1, obj;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            newDataToExport = (_a = rtnData === null || rtnData === void 0 ? void 0 : rtnData.data) === null || _a === void 0 ? void 0 : _a.data;
                            console.log(newDataToExport, '==log rtnData');
                            //[back] resume below
                            console.log(newDataToExport, '==newDataToExport');
                            objIndex = 0;
                            _i = 0, newDataToExport_1 = newDataToExport;
                            _b.label = 1;
                        case 1:
                            if (!(_i < newDataToExport_1.length)) return [3 /*break*/, 4];
                            obj = newDataToExport_1[_i];
                            console.log('==obj here', obj);
                            return [4 /*yield*/, ExportFunc({
                                    data: obj,
                                    dataGridHeader: dataGridHeader,
                                    subjectId: subjectIdArr[objIndex],
                                    retinADReports: obj.retinADReports
                                })];
                        case 2:
                            _b.sent();
                            objIndex += 1;
                            _b.label = 3;
                        case 3:
                            _i++;
                            return [3 /*break*/, 1];
                        case 4:
                            setDataToExport(newDataToExport);
                            setIsLoading(false);
                            notify("Data exported");
                            closeDialog();
                            return [2 /*return*/];
                    }
                });
            }); })
                .catch(function (error) {
                // Handle any errors that occurred
                console.error(error);
                setIsLoading(false);
                notify("Data export fail");
                closeDialog();
            });
            // csv download
            console.log(params, '==params');
            return [2 /*return*/];
        });
    }); };
    var closeDialog = function () {
        setDialogOpened(false);
    };
    //check access control list
    useEffect(function () {
        var _a;
        var newExportFields = __assign({}, exportFields);
        if (!acessControlList.dataExport["export-retinADProbScores"]) {
            delete newExportFields.retinADProbabilityScores;
        }
        setExportFields(newExportFields);
        // get ACL
        var cachedAuth = (_a = JSON.parse(localStorage.getItem('auth'))) !== null && _a !== void 0 ? _a : {};
        var settingsParams = {};
        if (cachedAuth) {
            settingsParams.id = cachedAuth.id;
            dataProvider.getOneWithoutDataJSON("users", settingsParams)
                .then(function (rtnData) {
                var _a;
                var rtnACL = __assign({}, (_a = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _a === void 0 ? void 0 : _a.access);
                var newACList = __assign({}, ACLData);
                newACList.access = __assign({}, rtnACL);
                setAcessControlList(newACList);
            }).catch(function (error) {
                // Handle any errors that occurred
                console.error(error);
            });
        }
    }, []);
    return (_jsxs(Grid, { container: true, style: styles.dataExport, children: [_jsx(Grid, { item: true, xs: 8, children: _jsx(ExportList, { props: "subjects", exportFields: exportFields, data: data, setData: setData, filters: filters, setFilters: setFilters, isLoading: isLoading, setIsLoading: setIsLoading, previewExport: previewExport, dataGridHeader: dataGridHeader, setDataGridHeader: setDataGridHeader }) }), _jsx(Grid, { item: true, xs: 4, children: _jsx(RightPanel, { acessControlList: acessControlList, translate: translate, exportFields: exportFields, setExportFields: setExportFields, exportDateInfoFields: exportDateInfoFields, setExportDateInfoFields: setExportDateInfoFields }) }), dialogOpened && _jsx(ExportDialog, { opened: dialogOpened, data: dialogData, 
                // openDialog={openDialog}
                closeDialog: closeDialog, reloadRecord: reloadRecord, translate: translate })] }));
};
export default DataExport;
