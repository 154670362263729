import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppBar } from 'react-admin';
import { Box, useMediaQuery } from '@mui/material';
// import Logo from '../assets/logos/header logo.png'
import ICSLogo from '../assets/logos/ics logo.png';
// import { AppBarToolbar } from './AppBarToolbar';
var CustomAppBar = function () {
    var isLargeEnough = useMediaQuery(function (theme) {
        return theme.breakpoints.up('sm');
    });
    return (_jsxs(AppBar, { style: {
            backgroundColor: "#1561a5", color: "white",
            // padding: "0.2rem",
            marginBottom: "1rem",
            '& .MuiIconButton-colorInherit': {}
        }, children: [isLargeEnough && _jsx("img", { src: ICSLogo, alt: "logo", height: 50 }), isLargeEnough && _jsx(Box, { component: "span", sx: { flex: 1 } })] }));
};
export default CustomAppBar;
