var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Box from '@mui/material/Box';
import SettingsIcon from '@mui/icons-material/Settings';
import SubjectIcon from '@mui/icons-material/Subject';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import { useTranslate, MenuItemLink, useSidebarState, } from 'react-admin';
import SubMenu from './SubMenu';
var Menu = function (_a) {
    var _b = _a.dense, dense = _b === void 0 ? false : _b;
    var _c = useState({
        main: true,
        userManagement: true,
    }), state = _c[0], setState = _c[1];
    var translate = useTranslate();
    var open = useSidebarState()[0];
    var handleToggle = function (menu) {
        setState(function (state) {
            var _a;
            return (__assign(__assign({}, state), (_a = {}, _a[menu] = !state[menu], _a)));
        });
    };
    return (_jsxs(Box, { sx: {
            width: open ? 200 : 50,
            marginTop: 3,
            marginBottom: 1,
            transition: function (theme) {
                return theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                });
            },
        }, children: [_jsxs(SubMenu, { handleToggle: function () { return handleToggle('main'); }, isOpen: state.main, name: "pos.menu.main", dense: dense, icon: _jsx(FolderSpecialIcon, {}), children: [_jsx(MenuItemLink, { to: "/admin/subjects", state: { _scrollToTop: true }, primaryText: translate("pages.subject.name", {
                            smart_count: 2,
                        }), leftIcon: _jsx(SubjectIcon, {}), dense: dense }), _jsx(MenuItemLink, { to: "/admin/dataexport", state: { _scrollToTop: true }, primaryText: translate("pages.dataExport.name", {
                            smart_count: 2,
                        }), leftIcon: _jsx(FileDownloadIcon, {}), dense: dense })] }), _jsxs(SubMenu, { handleToggle: function () { return handleToggle('userManagement'); }, isOpen: state.userManagement, name: "pos.menu.userManagement", icon: _jsx(ManageAccountsIcon, {}), 
                // icon={<PersonIcon />}
                dense: dense, children: [_jsx(MenuItemLink, { leftIcon: _jsx(PersonIcon, {}), to: "/admin/users", state: { _scrollToTop: true }, primaryText: translate("pages.userList.name", {
                            smart_count: 2,
                        }), 
                        // leftIcon={<reviews.icon />}
                        dense: dense }), _jsx(MenuItemLink, { leftIcon: _jsx(PeopleIcon, {}), to: "/admin/organizations", state: { _scrollToTop: true }, primaryText: translate("pages.organizations.name", {
                            smart_count: 2,
                        }), 
                        // leftIcon={<reviews.icon />}
                        dense: dense })] }), _jsx(MenuItemLink, { leftIcon: _jsx(SettingsIcon, {}), to: "/admin/settings", state: { _scrollToTop: true }, primaryText: translate("pages.settings.name", {
                    smart_count: 2,
                }), 
                // leftIcon={<reviews.icon />}
                dense: dense, sx: { fontSize: '0.8rem' } })] }));
};
export default Menu;
