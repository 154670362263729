var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Datagrid, DateField, List, TextField, useDataProvider, useNotify, useRefresh, } from 'react-admin';
import { Typography, Grid, Zoom, } from '@mui/material';
// individual panel component
var PanelComp = function (_a) {
    var icon = _a.icon, text = _a.text, setClickedPanelComp = _a.setClickedPanelComp, backgroundColor = _a.backgroundColor;
    return (_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", padding: 0.5, style: {
            cursor: "pointer",
            backgroundColor: backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : "white"
        }, onClick: function () { }, children: [_jsx(Typography, { variant: "body", sx: { paddingRight: "0.5rem" }, children: icon }), _jsx(Typography, { variant: "body", children: text })] }));
};
var classes = {
    noMarginDialog: {
        '&>div:nth-child(3)': {
            '&>div': {
                margin: 0,
            },
        },
    }
};
var Transition = React.forwardRef(function Transition(props, ref) {
    return _jsx(Zoom, __assign({ ref: ref }, props));
});
var GroupComp = function (_a) {
    var generalData = _a.generalData, data = _a.data, params = _a.params, translate = _a.translate, filters = _a.filters, setFilters = _a.setFilters, closeDialog = _a.closeDialog, opened = _a.opened, reloadRecord = _a.reloadRecord, setDialogData = _a.setDialogData;
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var location = useLocation();
    var navigate = useNavigate();
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    return (_jsx(_Fragment, { children: _jsx(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "start", justifyContent: "start", width: "100%", backgroundColor: "", children: _jsx(List, { resource: "organizations", style: { width: "100%", backgroundColor: "" }, children: _jsxs(Datagrid, { data: data, children: [_jsx(TextField, { source: "id", label: "Group ID" }), _jsx(TextField, { source: "name", label: "Group Name" }), _jsx(TextField, { source: "userNum", label: "Number of Users" }), _jsx(DateField, { source: "createdAt", label: "Created At" }), _jsx(DateField, { source: "updatedAt", label: "Updated At" })] }) }) }) }));
};
export default GroupComp;
